
var Constants = require('../constants/AppConstants');
var CHANGE_EVENT = Constants.CHANGE_EVENT;
var AppDispatcher = require('../dispatcher/AppDispatcher');
var EventEmitter = require('events').EventEmitter;
var objectAssign = require('object-assign');
var toast = require('react-toastify').toast;

var _videos = [];
var _q;
var _recent = window.DATA.recent;

var ytVideos = [],
    scVideos = [],
    ytPlaylists = [];

var _searching = false;
var _expandedPlRequest = false;
var toastId = 'no-videos-found';

function allSearchesReturned(){
  return ytVideos !== null &&
    scVideos !== null &&
    ytPlaylists !== null;
}

function searchReturned(terms = [], source, expanded){
  if(expanded){
    _videos = terms.map(v => v && v[0]).filter(v => !!v);
    return;
  }

  if(source === 'youtube' || !source){
    ytVideos = terms;
  } else if(source === 'soundcloud'){
    scVideos = terms;
  } else if(source === 'youtube-playlist'){
    ytPlaylists = terms;
  }

  _videos = [].concat(
    ytPlaylists || [],
    ytVideos || [],
    scVideos || []
  );

  if(!_videos.length && 
    allSearchesReturned() &&
    (!toastId || !toast.isActive(toastId))){
    toast('Sorry, no results found.', {
      position: toast.POSITION.TOP_CENTER,
      toastId
    });
  }

}

function resetSearch(){
  _videos = [];
  ytVideos = null;
  scVideos = null;
  ytPlaylists = null;
}


function setQuery(q){
  _q = q;
}

function setRecentTerms(t){
  _recent = t;
}

function setSearching(searching){
  _searching = searching;
}

function setExpandedRequest(r){
  _expandedPlRequest = r;
}

var SearchStore = objectAssign({}, EventEmitter.prototype, {

  getVideos: function(){
    return _videos;
  },

  getRecentTerms: function(){
    return _recent;
  },

  getCurrentQuery: function(){
    var q =  _q ? decodeURIComponent(_q) : '';
    return q;
  },

  getSearching: function(){
    return _searching;
  },

  getExpandedRequest: function(){
    return _expandedPlRequest;
  },

  emitChange: function(){
    this.emit(CHANGE_EVENT);
  },

  addChangeListener: function(callback) {
    this.on(CHANGE_EVENT, callback);
  },

  removeChangeListener: function(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

});

// Register to handle all updates
AppDispatcher.register(function(payload) {
  var action = payload.action;

  switch(action.actionType) {
    case Constants.QUERY:
      setSearching(true);
      setQuery(action.response.query);
      SearchStore.emitChange();
    break;
    case Constants.NEW_SEARCH:
      setSearching(true);
      resetSearch();
      SearchStore.emitChange();
      break;
    case Constants.SEARCH_SUCCESS:
      setSearching(false);
      searchReturned(action.response.items, action.response.source, action.response.expanded);
      SearchStore.emitChange();
    break;
    case Constants.EMPTY_RESULTS_PARTIAL:
      setSearching(false);
      resetSearch();
    break;
    case Constants.RESET_RESULTS:
      setQuery('');
      resetSearch();
      setSearching(false);
      SearchStore.emitChange();
    break;
    case Constants.RECENT_TERMS:
      setRecentTerms(action.response.terms);
      SearchStore.emitChange();
    break;

    case Constants.EXPAND_PL_REQUEST:
      setExpandedRequest(true);
      SearchStore.emitChange();
    break;

    case Constants.EXPAND_PL_RESPONSE:
      setExpandedRequest(false);
      SearchStore.emitChange();
    break;

  }
});


module.exports = SearchStore;
