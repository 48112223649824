var React = require("react");
var cookie = require("cookie");
var isMobile = require("ismobilejs");
var page = require("page");
var { toast } = require("react-toastify");

var Radio = require("../utils/Radio");

var PlaylistStore = require("../stores/PlaylistStore");
var PlaylistsStore = require("../stores/PlaylistsStore");
var SearchStore = require("../stores/SearchStore");
var UserStore = require("../stores/UserStore");
var UserDetailsStore = require("../stores/UserDetailsStore");
var ChatStore = require("../stores/ChatStore");

var PlaylistActions = require("../actions/PlaylistActions");
var PlaylistsActions = require("../actions/PlaylistsActions");
var SearchActions = require("../actions/SearchActions");
var UserActions = require("../actions/UserActions");
var UserDetailsActions = require("../actions/UserDetailsActions");

var PLAY_MODES = require("../constants/AppConstants").playModes;

var StateHandler = React.createClass({
  getInitialState: function () {
    return {
      playlist: PlaylistStore.getPlaylist(),
      results: SearchStore.getVideos(),
      recentTerms: SearchStore.getRecentTerms(),
      position: PlaylistStore.getPosition(),
      playlistId: PlaylistStore.getPlaylistId(),
      playlistSize: PlaylistStore.getPlaylistSize(),
      playlistSettings: PlaylistStore.getPlaylistSettings(),
      playlistVersion: PlaylistStore.getPlaylistVersion(),
      paused: PlaylistStore.getPaused(),
      sync: PlaylistStore.getSync(),
      currentQuery: SearchStore.getCurrentQuery(),
      showFeedbackForm: false,
      hdOnly: false,
      radio: false,
      searching: false,
      expandedPlRequest: false,
      repeatMode: PLAY_MODES.repeat.off,
      shuffleActive: false,
      popularPlaylists: PlaylistStore.getPopularPlaylists(),
      partyClients: PlaylistStore.getPartyClients(),
      ts: PlaylistStore.getPlaylistTs(),
      dts: PlaylistStore.getPlaylistDelta(),
      autoSave: this.props.autoSave,
      myPlaylists: PlaylistStore.getMyPlaylists(),
      myPlaylistsSummary: PlaylistStore.getMyPlaylistsSummary(),
      user: UserStore.getUser(),
      playlistUser: PlaylistStore.getPlaylistUser(),
      playlists: PlaylistsStore.getPlaylists(),
      count: PlaylistsStore.getPlaylistCount(),
      lastQuery: PlaylistsStore.getLastQuery(),

      playlistsUserDetails: UserDetailsStore.getUserDetails(),
      playlistsUserError: UserDetailsStore.getUserError(),

      messages: [],
    };
  },

  componentWillReceiveProps: function (nextProps, nextState) {
    console.log("componentWillReceiveProps", this.props, nextProps, this.state);

    if (
      nextProps.playlistId &&
      !this.state.sync &&
      // changed the playlist through props (eg clicked link)
      (nextProps.playlistId !== this.props.playlistId ||
        // or simply changed version -- how exactly
        (nextProps.playlistId === this.props.playlistId &&
          nextProps.version !== this.props.version)) &&
      // and didn't just saved it
      (nextProps.playlistId !== this.state.playlistId ||
        +nextProps.version !== +this.state.playlistVersion)
    ) {
      // props updated - load new playlist
      if (this.state.autoSave) this.toggleAutoSave();
      this.clearPlaylist();
      this.loadPlaylist(nextProps.playlistId, nextProps.version);
    } else if (
      !nextProps.playlistId &&
      this.state.playlistId &&
      nextProps.view === "search"
    ) {
      // props cleared - clear playlist
      if (this.state.autoSave) this.toggleAutoSave();
      PlaylistActions.reset();
    } else if (
      this.props.playlistId &&
      nextProps.playlistId &&
      this.props.playlistId !== nextProps.playlistId &&
      this.state.playlistId !== nextProps.playlistId &&
      this.state.sync
    ) {
      // was in a party, clicked some other playlist
      PlaylistActions.unsync();
      this.clearPlaylist();
      this.loadPlaylist(nextProps.playlistId, nextProps.version);
    }

    var q = nextProps.search;
    if (q !== this.props.search) {
      q
        ? SearchActions.search(q, this.state.hdOnly ? "high" : "any")
        : SearchActions.resetResults();
    }

    /*
    if(nextProps.playlistsUser && (!this.props.playlistsUser || nextProps.playlistsUser !== this.props.playlistsUser)){
      UserDetailsActions.loadUserDetails(nextProps.playlistsUser)
    } else if(!nextProps.playlistsUser && this.props.playlistsUser){
      UserDetailsActions.clearUserDetails();
    }
    */
  },

  componentWillUpdate(nextProps, nextState) {
    if (
      this.state.myPlaylists !== nextState.myPlaylists &&
      !this.state.user &&
      nextState.myPlaylists
    ) {
      PlaylistActions.describeMyPlaylists(
        nextState.myPlaylists.map((p) => p[0])
      );
    }
  },

  componentDidMount: function () {
    var that = this;

    PlaylistStore.addChangeListener(this._onChange);
    PlaylistsStore.addChangeListener(this._onChange);
    SearchStore.addChangeListener(this._onChange);
    UserStore.addChangeListener(this._onChange);
    UserDetailsStore.addChangeListener(this._onChange);
    ChatStore.addChangeListener(this._onChange);

    var q = this.props.search;
    if (q) {
      SearchActions.search(q, this.state.hdOnly ? "high" : "any");
    }

    // if you're accessing via a mobile device and there are parties for your IP
    // you'll be automatically added to the parteh
    if (
      window.DATA.playlists &&
      window.DATA.playlists.length &&
      !this.props.playlistId
    ) {
      if (isMobile.any) {
        var id = window.DATA.playlists[0];
        history.pushState(null, null, "/" + id);
        PlaylistActions.sync(id, this.state.playlist, this.state.position);
      } else {
        console.log(
          "playlist in this network detected:",
          window.DATA.playlists[0]
        );
      }
    } else if (this.props.playlistId && this.props.party) {
      PlaylistActions.sync(this.props.playlistId);
      history.pushState(null, null, this.removeQueryParam("party"));
    } else if (this.props.playlistId) {
      this.loadPlaylist(this.props.playlistId, this.props.version);
    }

    var cookiePlaylists = PlaylistStore.parseCookie();

    let hasUser = !!window.DATA.user;
    if (hasUser && window.DATA.userPlaylists) {
      //PlaylistActions.describeMyPlaylists(window.DATA.userPlaylists);
      //PlaylistActions.get
    } else if (this.state.user) {
      PlaylistActions.getOwnPlaylists();
    }

    if (hasUser && cookiePlaylists.length) {
      // transition from cookie to users
      PlaylistActions.setUserToMyPlaylists(cookiePlaylists.map((ar) => ar[0]));
      PlaylistActions.resetCookie();
    } else if (!hasUser && cookiePlaylists.length) {
      PlaylistActions.setMyPlaylists(cookiePlaylists);
    }
  },

  _onChange: function () {
    this.setState({
      playlist: PlaylistStore.getPlaylist(),
      playlistId: PlaylistStore.getPlaylistId(),
      playlistSettings: PlaylistStore.getPlaylistSettings(),
      playlistSize: PlaylistStore.getPlaylistSize(),
      playlistVersion: PlaylistStore.getPlaylistVersion(),
      position: PlaylistStore.getPosition(),
      paused: PlaylistStore.getPaused(),
      sync: PlaylistStore.getSync(),
      results: SearchStore.getVideos(),
      currentQuery: SearchStore.getCurrentQuery(),
      recentTerms: SearchStore.getRecentTerms(),
      popularPlaylists: PlaylistStore.getPopularPlaylists(),
      partyClients: PlaylistStore.getPartyClients(),
      ts: PlaylistStore.getPlaylistTs(),
      dts: PlaylistStore.getPlaylistDelta(),
      myPlaylists: PlaylistStore.getMyPlaylists(),
      myPlaylistsSummary: PlaylistStore.getMyPlaylistsSummary(),
      searching: SearchStore.getSearching(),
      waitingForPlaylistResult: SearchStore.getExpandedRequest(),
      playlistUser: PlaylistStore.getPlaylistUser(),
      user: UserStore.getUser(),

      playlists: PlaylistsStore.getPlaylists(),
      count: PlaylistsStore.getPlaylistCount(),
      lastQuery: PlaylistsStore.getLastQuery(),

      playlistsUserDetails: UserDetailsStore.getUserDetails(),
      playlistsUserError: UserDetailsStore.getUserError(),

      messages: ChatStore.getMessages(),
    });

    console.log("new state", this.state);
  },

  /**
   *  Methods
   */

  handleSavePlaylist: function (fn) {
    var newPlaylist = !this.state.playlistId;

    if (
      newPlaylist &&
      (!this.state.playlist.length || this.state.playlist.length === 1)
    ) {
      toast("Please add at least 2 tracks before saving the playlist!", {
        //toast('You need to add some songs before you can save the playlist!', {
        position: toast.POSITION.TOP_CENTER,
      });

      return;
    }

    PlaylistActions.save(
      this.state.playlist,
      this.state.playlistId,
      (newPlaylist, { id, hash }) => {
        console.log(" Just saved this !!!", newPlaylist, id, hash);
        if (newPlaylist && !this.state.user) {
          this.setMyPlaylistCookie(id, hash);
        }

        fn(id, newPlaylist); //topbar stuff

        if (this.state.user) {
          PlaylistActions.getOwnPlaylists();
        }
      }
    );
  },

  toggleSync: function () {
    var sync = !this.state.sync;
    console.log("setting sync to ", sync);

    if (sync) {
      if (!this.state.playlistId) {
        PlaylistActions.createAndSync(this.state.playlist, this.state.position);
      } else {
        PlaylistActions.sync(
          this.state.playlistId,
          this.state.playlist,
          this.state.position
        );
      }
      history.replaceState(null, null, this.addQueryParam("party", "true"));
    } else {
      PlaylistActions.unsync();
      history.replaceState(null, null, this.removeQueryParam("party"));
    }
  },

  setQuery: function (q) {
    if (this.props.view === "playlists") {
      if (q) {
        page.replace(`/?q=${q}`);
      }
    } else {
      this.props.setQueryStringValue("q", q);
    }
  },

  //searchForPlaylists(q, page = 1){
  //  page('/playlists/1');
  //  PlaylistsActions.load(24, page, q);
  //},

  setHdOnly: function (hdOnly) {
    this.setState({ hdOnly: hdOnly });
    if (this.state.currentQuery) {
      SearchActions.search(this.state.currentQuery, hdOnly ? "high" : "any");
    }
  },

  setPosition: function (p) {
    console.log("set position", p);
    var changes = {
      position: p,
    };

    if (this.state.sync) {
      changes.playlist = this.state.playlist;
    }

    PlaylistActions.changedPlaylist(
      this.state.playlistId,
      changes,
      this.state.sync
    );
  },

  getPlaylistPosition: function (id) {
    if (this.state.playlist.length) {
      if (
        this.state.playlist[this.state.position] &&
        this.state.playlist[this.state.position].id == id
      ) {
        // i'm currently playing - always show currently playing
        return 0;
      }
    }

    for (var i = this.state.playlist.length - 1; i >= 0; i--) {
      if (this.state.playlist[i].id === id) {
        if (i < this.state.position) {
          return -1;
        } else {
          return 1;
        }
      }
    }
  },

  clearPlaylist() {
    PlaylistActions.clear();
  },

  loadPlaylist: function (id, version, preventRedirect) {
    this.setPosition(-1);

    PlaylistActions.load(id, version, preventRedirect, (id, pl) => {
      if (pl.length) {
        this.setPosition(0);
      }
    });
  },

  setShuffleActive: function (shuffle) {
    this.setState({ shuffleActive: shuffle });
  },

  setRepeatMode: function (repeatMode) {
    this.setState({ repeatMode: repeatMode });
  },

  playerReady: function () {
    console.warn("yt player ready");
    if (this.state.playlist.length) {
      setTimeout(() => {
        var targetPosition = this.state.position;
        if (this.state.position !== -1) {
          this.setPosition(-1);
        }
        this.setPosition(targetPosition);
        this.isPlayerReady = true;
      }, 50);
    } else {
      this.isPlayerReady = true;
    }
  },

  setPlaylistChange: function (obj) {
    console.log("set playlist change", obj);

    if (this.state.sync && !obj.playlist) {
      // if party mode, always include current playlist, even if it doesnt change
      // this will allow the 1st sync request to set the current playlist version
      // on the server
      obj.playlist = this.state.playlist;
    }

    PlaylistActions.changedPlaylist(
      this.state.playlistId,
      {
        playlist: obj.playlist,
        position: obj.position,
        settings: obj.settings,
      },
      this.state.sync
    );

    if (obj.playlist && !this.state.sync && this.state.autoSave) {
      PlaylistActions.save(obj.playlist, this.state.playlistId);
    }
  },

  toggleRadioMode: function () {
    this.setState({ radio: !this.state.radio });
  },

  addNewSong: function (play) {
    Radio.getNextTrack((video) => {
      if (video) {
        var evt = new CustomEvent(play ? "playNow" : "enqueue", {
          detail: { video: video, source: "radio" },
        });

        window.dispatchEvent(evt);
      }
    });
  },

  toggleAutoSave: function () {
    this.setState({ autoSave: !this.state.autoSave });
  },

  setMyPlaylistCookie: function (id, hash) {
    PlaylistActions.addPlaylistToCookie(id, hash);
  },

  togglePrivate(e) {
    PlaylistActions.updatePlaylistSettings(
      this.props.playlistId,
      undefined,
      !e.target.checked,
      "private"
    );
  },

  removeQueryParam: function (paramKey) {
    var path = window.location.pathname;
    var search = window.location.search;
    var hash = window.location.hash;

    if (search) {
      var newSearch = search
        .slice(1)
        .split("&")
        .reduce((acc, pair) => {
          var param = pair.split("=");
          if (param[0] !== paramKey) {
            if (acc) acc += "&";
            return acc + `${param[0]}=${param[1]}`;
          }
          return acc;
        }, "");

      search = newSearch ? `?${newSearch}` : "";
    }

    return path + search + hash;
  },

  addQueryParam: function (key, val) {
    var search = window.location.search;
    search += search ? "&" : "?";
    search += `${key}=${val}`;

    return window.location.pathname + search + window.location.hash;
  },

  videoPaused() {
    if (this.state.sync) {
      PlaylistActions.sendPauseEvent();
    }
  },

  videoPlaying() {
    if (this.state.sync) {
      PlaylistActions.sendPlayEvent();
    }
  },

  logout() {
    var yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    document.cookie = cookie.serialize("greatdjSession", "", {
      domain: "." + location.hostname,
      expires: yesterday,
    });

    UserActions.logout();
    UserActions.unsetUser();
    PlaylistActions.clearMyPlaylists();

    this.setState({
      user: null,
    });
  },

  // hideFeedbackForm: function(){
  //   this.setState({showFeedbackForm: false});
  // },

  // showFeedbackForm: function(e){
  //   this.setState({showFeedbackForm: true});
  //   return false;
  // },

  /**
   * Render
   */

  render: function () {
    var childView = React.Children.only(this.props.children);

    return React.cloneElement(
      childView,
      Object.assign({}, this.state, {
        search: this.props.search,
        playlistSearch: this.props.playlistSearch,
        view: this.props.view,
        playlistsPage: this.props.playlistsPage,
        playlistsUser: this.props.playlistsUser,
        handleSavePlaylist: this.handleSavePlaylist,
        toggleSync: this.toggleSync,
        setQuery: this.setQuery,
        setHdOnly: this.setHdOnly,
        setPosition: this.setPosition,
        getPlaylistPosition: this.getPlaylistPosition,
        loadPlaylist: this.loadPlaylist,
        setShuffleActive: this.setShuffleActive,
        setRepeatMode: this.setRepeatMode,
        playerReady: this.playerReady,
        setPlaylistChange: this.setPlaylistChange,
        toggleRadioMode: this.toggleRadioMode,
        addNewSong: this.addNewSong,
        toggleAutoSave: this.toggleAutoSave,
        togglePrivate: this.togglePrivate,
        logout: this.logout,
        videoPaused: this.videoPaused,
        videoPlaying: this.videoPlaying,
      })
    );
  },

  componentWillUnmount() {
    PlaylistStore.removeChangeListener(this._onChange);
    PlaylistsStore.removeChangeListener(this._onChange);
    SearchStore.removeChangeListener(this._onChange);
    UserStore.removeChangeListener(this._onChange);
    UserDetailsStore.removeChangeListener(this._onChange);
    ChatStore.removeChangeListener(this._onChange);
  },
});

module.exports = StateHandler;
