/* globals process, gapi */

var PlaylistStore;
if(process.env.BROWSER){
  PlaylistStore = require('../stores/PlaylistStore');
}

var React = require('react');
var ModalMixin = require('./Modal');
var cookie = require('cookie');
var UserActions = require('../actions/UserActions');
var PlaylistActions = require('../actions/PlaylistActions');
//var Box = require('3box')
var toast = require('react-toastify').toast;

var secrets = require('../constants/secrets');

if(process.env.BROWSER){
  var AuthProviders = {
    spotify: {
      authUrl: 'https://accounts.spotify.com/authorize/',
      clientId: secrets.spotifyClientId,
      redirect_uri: encodeURIComponent(window.location.origin + '/spotify_callback')
    },
    facebook: {
    },
  };
}

module.exports = React.createClass({
  mixins: [ModalMixin],
  modalId: 'loginModal',

  getInitialState: function(){
    return {
      username: '',
      password: ''
    }
  },

  getTitle: () => "Login to your Great DJ account.",

  getBody: function(){
    return (
  /*
      <div className="login-form-container">
        <button className="login-with login-with--spotify" onClick={this.handleSpotifyLogin}>Login with Spotify</button>
        <button className="login-with login-with--facebook" onClick={this.handleFacebookLogin}>Login with Facebook</button>
        <button className="login-with login-with--gdj" onClick={this.handleSignup}>Login with Great DJ</button>
      </div>
    );
  },
      <form className="login-form" onSubmit={this.submitLoginForm}>
        <div>
          <label htmlFor="email">Email address:</label>
          <input ref="emailInput" name="email" className="form-text-input" type="text" value={this.state.username} onChange={this.handleUsernameChange} />
        </div>
        <div>
          <label htmlFor="password">Password:</label>
          <input name="password" className="form-text-input" type="password" value={this.state.password} onChange={this.handlePasswordChange} />
        </div>
        <button type="submit">Login</button>
      </form>
      <p>First time here? <a href="#">Create an account instead.</a></p>
      <p>Alternatively, you can also...</p>
  */
    <div className="login-form-container">
      <button className="login-with login-with--spotify" onClick={this.handleSpotifyLogin}>
          <img src={ window._jsRoot + "static/images/spotify-logo.png" } />
          Login with Spotify
        </button>
        <button className="login-with login-with--google" ref="login-google">
          <img src={ window._jsRoot + "static/images/g-logo.png" } />
          Login with Google
        </button>
        <button style={{display:'none'}} className="login-with login-with--eth" onClick={this.handle3BoxLogin}>
          <img src={ window._jsRoot + "static/images/eth-logo.png" } />
          Login with 3Box (requires Metamask)
        </button>
    </div>
    );
  },

  getFooter: () => '',

  handleSpotifyLogin: function(e){
    e.preventDefault();
    var cookies = cookie.parse(document.cookie);
    if(!cookies.greatdjSession){
      window.location.href = '/?error=1';
      return;
    }
    window.location.href = this.getAuthUrl('spotify');
  },

  _handle3BoxLogin: function(e){
    if(!window.ethereum){
      toast.error(`No ethereum provider found. This option requires Metamask to be installed.`, {
        position: toast.POSITION.TOP_CENTER
      });
      return;
    }

    const syncComplete = (data) => {
      console.log('syncComplete', data);
      let profile, email;

      window.box.public.all()
        .then(res => {
          profile = res;
          return window.box.private.get('email');
        })
        .then(res => {
          email = res;

          if(!email){
            throw new Error('Could not find your email address');
          }

          let user = {
            email,
            display_name: profile.name,
            did: profile.did
          };

          if(profile.image && profile.image[0]){
            user.images = [{
              url: 'https://ipfs.infura.io/ipfs/' + profile.image[0].contentUrl['/']
            }]
          }

          UserActions.upsertUser(user, (err, resp) => {
            if(err){
              toast.error('Sorry, could not log you in' + (err ? ': ' + err : ''), {
                position: toast.POSITION.TOP_CENTER
              });
              return;
            }
            UserActions.setUser(resp.body.user);
            PlaylistActions.getOwnPlaylists();
            this.hide();

            // set profile address on 3box
            window.box.public.get('greatdj.profile')
              .then(res => {
                let profile = `https://great.dj/playlists/${resp.body.user.username}`;
                if(!res || res !== profile){
                  window.box.public.set('greatdj.profile', profile)
                    .then(res => console.log('profile set', res))
                }
              })
              .catch(err => console.log(err));
          })
        })
        .catch(err => {
          toast.error('Sorry, could not log you in' + (err ? ': ' + err : ''), {
            position: toast.POSITION.TOP_CENTER
          });
        });
    }

      /*
    window.ethereum.enable().then(addresses => {
      Box.openBox(addresses[0],  window.ethereum, {}).then(box => {
        box.onSyncDone(syncComplete);
        window.box = box;
        console.log(box);
      })
    });
    */

  },

  handleFacebookLogin: (e) => {
    e.preventDefault();

  },

  getAuthUrl: function(service){
    var config = AuthProviders[service];
    return config.authUrl + `?
client_id=${config.clientId}&
response_type=code&
redirect_uri=${config.redirect_uri}&
state=${this.getState()}&
scope=${encodeURIComponent('playlist-read-private playlist-read-collaborative user-read-email user-top-read')}
`;

  },

  getState: () => {
    var cookies = cookie.parse(document.cookie);
    return cookies.greatdjSession.substring(0, 5);
  },

  shown(){
    const component = this;

    if(this.alreadyRendered){
      if(this._auth2) this.attachHandler(this._auth2);
      return;
    }

    this.alreadyRendered = true;

    window.gapi.load('auth2', function(){
      let auth2 = gapi.auth2.init({
        client_id: secrets.googleClientId,
        cookiepolicy: 'single_host_origin'
      });

      component._auth2 = auth2;
      component.attachHandler(auth2);
    });
  },

  attachHandler(auth2){
    const el = this.refs['login-google'];
    const component = this;

    if(!el) return;

    auth2.attachClickHandler(el, {},
      function(googleUser) {
        const token = googleUser.getAuthResponse().id_token;
        UserActions.authGoogleToken(token, (err, resp) => {
          if(err){ 
            console.log('Error auth on google token: ' + err);
            return;
          }

          UserActions.setUser(resp.body.user);

					var cookiePlaylists = PlaylistStore.parseCookie();

          if(cookiePlaylists.length){
            PlaylistActions.setUserToMyPlaylists(cookiePlaylists.map(ar => ar[0]));
            PlaylistActions.resetCookie();
          } else {
            PlaylistActions.getOwnPlaylists();
          }

          component.hide();

        });
      }, function(error) {
        console.log(JSON.stringify(error, undefined, 2));
        throw new Error(error);
      });
  }

/*
  postLoginDetails: function(){
    console.log(`should post ${this.state.email} - ${this.state.password}`);
  },

  handleUsernameChange: function(e){
    this.setState({username: e.target.value});
  },

  handlePasswordChange: function(e){
    this.setState({password: e.target.value});
  },

  submitLoginForm: function(e){
    e.preventDefault();
    console.log('login with', this.state.username, this.state.password)
  }
  */
})
