var React = require('react');

var Tooltip = React.createClass({

  addInClass: function(){
    if(this.isMounted() && !this.refs.tooltip.classList.contains('in'))
      this.refs.tooltip.classList.add('in');
  },

  render: function() {
    var that = this;

    var style = {};
    style['left'] = this.props.positionLeft;
    style['top'] = this.props.positionTop;

    var arrowStyle = {};
    arrowStyle['left'] = this.props.arrowOffsetLeft;
    arrowStyle['top'] = this.props.arrowOffsetTop;

    setTimeout(function(){that.addInClass()}, 10);

    return (
      <div ref="tooltip" className={'tooltip ' + this.props.placement + ' ' + (this.props.moreClasses || '')} style={style} onMouseOut={this.props.onMouseOut}>
          <div className="tooltip-arrow" style={arrowStyle} />
          <div className="tooltip-inner">
            {this.props.children}
          </div>
        </div>
    );
  }

});

module.exports = Tooltip;
