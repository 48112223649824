var Api = require('../utils/Api');
var Constants = require('../constants/AppConstants');
var AppDispatcher = require('../dispatcher/AppDispatcher');

var ChatActions = {
  sendMessage(message, playlistId) {
    Api.io.sendChatMessage(message);
    if(window && window.ga){
      window.ga('send', {
        hitType: 'event',
        eventCategory: 'Chat',
        eventAction: 'sendMessage',
        eventLabel: playlistId
      });
    }
    /*
    AppDispatcher.handleViewAction({
      actionType: Constants.PLAYLISTS_CLEAR
    });
    */
  },

}

module.exports = ChatActions;
