var ReactCSSTransitionGroup = require('react-transition-group/CSSTransitionGroup');
var React = require('react');

module.exports = {
  getInitialState: function(){
    return {
      shown: this.props.showing,
    }
  },

  getDefaultProps: function(){
    return {
      showBackdrop: true,
    }
  },

  componentDidMount(){
    if(this.state.shown){
      this.show();
      this.shown && this.shown();
      this.props.shown && this.props.shown();
    }
  },

  componentDidUpdate: function(prevProps, nextState){
    if(this.props.showing && !prevProps.showing){
      this.show(() => {
        this.shown && this.shown();
        this.props.shown && this.props.shown();
      });
    } 
    // else if(!this.props.showing && prevProps.showing){
    //   this.hide();
    // }
  },

  show: function(cb){
    this.setState({shown: true}, () => {
      document.body.classList.add('modal-in');
      cb();
    });
  },

  hide: function(){
    this.props.hidden();
    this.setState({shown: false});
    
    document.body.classList.remove('modal-in');
  },

  isShown: function(){
    return this.state.shown;
  },

  render: function(){
    var modalComponent = this.state.shown ? 
      <div className="modal" key={this.modalId}>
        <div className="modal-container">
          <div className="modal-header">
            <h1 className="pull-left">{this.getTitle()}</h1>
            <i className="pull-right fa fa-times" onClick={this.hide} />
          </div>
          <div className="modal-body">
            {this.getBody()}
          </div>
          <div className="modal-footer">
            {this.getFooter()}
            <button className="btn pull-right" onClick={this.hide}>Close</button>
          </div>
        </div>
      </div> : <span></span>;

    var backdropComponent = this.state.shown && this.props.showBackdrop ?
      this.renderBackdrop() : <span></span>;

    return (
      <div ref="modal">
        <ReactCSSTransitionGroup transitionName="modal" transitionEnterTimeout={750} transitionLeaveTimeout={500}>
          {modalComponent}
        </ReactCSSTransitionGroup>
        <ReactCSSTransitionGroup transitionName="backdrop" transitionEnterTimeout={750} transitionLeaveTimeout={500}>
          {backdropComponent}
        </ReactCSSTransitionGroup>
      </div>
    );
  },

  renderBackdrop: function(){
    return (
      <div className="backdrop" key="theBackdrop" onClick={this.hide}></div>
    )
  }
}
