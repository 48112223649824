var AppDispatcher = require('../dispatcher/AppDispatcher');
var EventEmitter = require('events').EventEmitter;
var Constants = require('../constants/AppConstants');
var CHANGE_EVENT = Constants.CHANGE_EVENT;
var objectAssign = require('object-assign');

var _playlists = window && window.DATA ? window.DATA.playlistSearchResults : undefined;
var _count;
var _lastQuery;

function loaded(data, query){
  _playlists = data.result;
  _count = data.count;
  _lastQuery = query;
}

function loading(){
  _playlists = undefined;
  _count = undefined;
  _lastQuery = undefined;
}

function clear(){
  _playlists = undefined;
  _count = undefined;
}

var PlaylistsStore = objectAssign(EventEmitter.prototype, {
  getPlaylists: function(){
    return _playlists;
  },

  getPlaylistCount: function(){
    return _count;
  },

  getLastQuery: function(){
    return _lastQuery;
  },

  //

  emitChange: function(){
    this.emit(CHANGE_EVENT);
  },

  addChangeListener: function(callback) {
    this.on(CHANGE_EVENT, callback);
  },

  removeChangeListener: function(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }
});


AppDispatcher.register(function(payload) {
  var action = payload.action;

  switch(action.actionType) {
    case Constants.PLAYLISTS_LOAD:
        loading();
        PlaylistsStore.emitChange();
    break;
    case Constants.PLAYLISTS_LOADED:
        loaded(action.response.data, action.response.query);
        PlaylistsStore.emitChange();
    break;
    case Constants.PLAYLISTS_CLEAR:
        clear();
        PlaylistsStore.emitChange();
    break;
  }

  return true;

});

module.exports = PlaylistsStore;
