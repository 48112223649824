module.exports = {
  PAUSE: "pause",
  PLAY: "play",
  PLAYLIST_SAVE: "save",
  PLAYLIST_LOAD: "load",
  PLAYLIST_SAVED: "saved",
  PLAYLIST_LOADED: "loaded",
  PLAYLIST_CLEAR_CURRENT: "clear_current",
  PLAYLIST_CHANGE: "change",
  PLAYLIST_CHANGED: "changed",
  PLAYLISTS_LOAD: "playlists_load",
  PLAYLISTS_LOADED: "playlists_loaded",
  PLAYLISTS_CLEAR: "playlists_clear",
  SET_PLAYLIST_ID: "set_playlist_id",
  UPDATE_PLAYLIST_SETTINGS: "update_playlist_settings",
  SET_PLAYLIST: "set_playlist_content",
  UNSET_PLAYLIST_ID: "unset",
  USER_UPDATE_USERNAME: "user_update_username",
  USER_LOADED: "USER_LOADED",
  USER_DETAILS_LOADED: "USER_DETAILS_LOADED",
  USER_DETAILS_CLEAR: "USER_DETAILS_CLEAR",
  USER_DETAILS_USER_NOT_FOUND: "USER_DETAILS_USER_NOT_FOUND",
  USER_UNSET_FL: "USER_UNSET_FL",
  USER_UNSET: "USER_UNSET",
  NEW_SEARCH: "new_search",
  SEARCH_SUCCESS: "search_success",
  CHANGE_EVENT: "change_event",
  QUERY: "query",
  RESET: "reset",
  RESET_RESULTS: "RESET_RESULTS",
  EMPTY_RESULTS_PARTIAL: "EMPTY_RESULTS_PARTIAL",
  RECENT_TERMS: "RECENT_TERMS",
  PARTY_PRESENCE: "PARTY_PRESENCE",
  POPULAR_PLAYLISTS: "POPULAR_PLAYLISTS",
  UPDATE_MY_PLAYLISTS: "UPDATE_MY_PLAYLISTS",
  SET_MY_PLAYLISTS: "SET_MY_PLAYLISTS",
  RESET_COOKIE: "RESET_COOKIE",
  SYNC_ON: "SYNC_ON",
  SYNC_OFF: "SYNC_OFF",
  DESCRIBE_MY_PLAYLISTS: "DESCRIBE_MY_PLAYLISTS",
  EXPAND_PL_REQUEST: "EXPAND_PL_REQUEST",
  EXPAND_PL_RESPONSE: "EXPAND_PL_RESPONSE",
  CLEAR_MY_PLAYLISTS: "CLEAR_MY_PLAYLISTS",
  CHAT_MESSAGE: "CHAT_MESSAGE",
  SOCKET_ERROR: "SOCKET_ERROR",
  request: {
    PENDING: "pending",
    COMPLETE: "complete",
    ERROR: "ups",
  },
  actionSource: {
    VIEW: "view",
    SOURCE: "source",
  },
  playModes: {
    repeat: {
      off: "repeat_off",
      one: "repeat_one",
      all: "repeat_all",
    },
    shuffle: "shuffle",
  },
};
