var Api = require('../utils/Api');
var Constants = require('../constants/AppConstants');
var AppDispatcher = require('../dispatcher/AppDispatcher');

var PlaylistsActions = {
  load: function(perPage, page, query, user) {
    Api.loadAllPlaylists(perPage || 24, page || 0, query, user);
  },
  clear(){
    AppDispatcher.handleViewAction({
      actionType: Constants.PLAYLISTS_CLEAR
    });
  }
}

module.exports = PlaylistsActions;
