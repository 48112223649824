var AppDispatcher = require('../dispatcher/AppDispatcher');
var EventEmitter = require('events').EventEmitter;
var Constants = require('../constants/AppConstants');
var CHANGE_EVENT = Constants.CHANGE_EVENT;
var objectAssign = require('object-assign');

let _messages = [];

function received(message){
  message.date = new Date();
  _messages = _messages.concat([message]);
}

function receivedPresence(data){
  let message;

  if(data.action){
    if(data.action === 'connect'){
      message = {
        text: `${data.user} connected (${data.clients} on this party now)`,
        type: 'presence'
      };
    } else if(data.action === 'disconnect'){
      message = {
        text: `${data.user} disconnected (${data.clients} on this party now)`,
        type: 'presence'
      };
    }
  } else {
    let clientsWord = data.clients === 1 ? 'client' : 'clients';
    message = {
      text: `${data.clients} ${clientsWord} connected`,
      type: 'presence'
    };
  }

  if(message){
    _messages = _messages.concat([message]);
  }
}

function recievedChange(data){
  if(data.position !== undefined && !data.positionIsTheSame){  
    _messages = _messages.concat([{
      text: `Track #${data.position + 1} selected${data.user ? ` by ${data.user}` : ''}`,
      type: 'presence'
    }]);
  }
}

function recievedPause(data){
  _messages = _messages.concat([{
    text: `${data.user} paused the player.`,
    type: 'presence'
  }]);
}

function recievedPlay(data){
  _messages = _messages.concat([{
    text: `${data.user} resumed the player.`,
    type: 'presence'
  }]);
}


function clear(message){
  _messages = [];
}

var ChatStore = objectAssign(EventEmitter.prototype, {
  getMessages: () => _messages,

  emitChange: function(){
    this.emit(CHANGE_EVENT);
  },

  addChangeListener: function(callback) {
    this.on(CHANGE_EVENT, callback);
  },

  removeChangeListener: function(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }
});


AppDispatcher.register(function(payload) {
  var action = payload.action;

  switch(action.actionType) {
    case Constants.CHAT_MESSAGE:
      received(action.response);
      ChatStore.emitChange();
      break;

    case Constants.SYNC_ON:
      clear();
      ChatStore.emitChange();
      break;

    case Constants.PARTY_PRESENCE:
      receivedPresence(action.response);
      ChatStore.emitChange();
      break;

    case Constants.PLAYLIST_LOADED:
      recievedChange(action.response);
      ChatStore.emitChange();
      break;

    case Constants.PAUSE:
      recievedPause(action.response);
      ChatStore.emitChange();
      break;

    case Constants.PLAY:
      recievedPlay(action.response);
      ChatStore.emitChange();
      break;

  }

  return true;

});

module.exports = ChatStore;
