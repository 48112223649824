
var _ = require('underscore');
var Api = require('./Api');

var _recommendations = {};
var _videoProps = {};
var _videosAdded = [];

var Radio = {
  reset: function(){
    _recommendations = {};
    _videoProps = {};
    _videosAdded = [];
  },

  recordVideoPlayed: function(video, playlist, hdOnly, fn){
    if(video.source !== 'youtube') return;
    if(_videosAdded.indexOf(video.id) > -1) return;
    _videosAdded.push(video.id);

    console.log('recorded video played for', video, _recommendations)

    var ytPl = playlist.filter(function(v){ return v.source === 'youtube' });
    ytPl.forEach(function(v){
      delete _recommendations[v.id];
    });

    Api.getRelatedVideos(video.id, (hdOnly ? 'high' : 'any'), function(videos){

      videos.forEach(function(v){
        if(_recommendations[v.id]){
          _recommendations[v.id] += 1;
        } else if(ytPl.indexOf(v.id) === -1) {
          _recommendations[v.id] = 1;
          _videoProps[v.id] = v;
        }
      })

      console.log('recommended now', _recommendations)

      if(fn) fn();
    });
  },

  getNextTrack: function(fn){
    if(!Object.keys(_recommendations)) return;

    var max = _recommendations[_.max(Object.keys(_recommendations), function (o) { return _recommendations[o]; })];
    var poll = _.filter(Object.keys(_recommendations), function(o){
      return _recommendations[o] > max * 0.8 || Math.random() < .16
    });

    var chosenId = _.sample(poll);
    delete _recommendations[chosenId];
    fn(_videoProps[chosenId]);

    // decaying?
    Object.keys(_recommendations).forEach(function(key){
      _recommendations[key] *= 0.85;
      _recommendations[key] = Math.round(_recommendations[key] * 100) / 100
    })
  },


  removeTrackFromRecommendations: function(video, hdOnly){
    console.log('removing track', video.id, 'from', _recommendations);
    if(video.source !== 'youtube') return;

    // this is cached on the api class so its fine
    Api.getRelatedVideos(video.id, (hdOnly ? 'high' : 'any'), function(videos){
      videos.forEach(function(v){
        _recommendations[v.id] = _recommendations[v.id] || 0;
        _recommendations[v.id] *= .85;
        _recommendations[v.id] = Math.round(_recommendations[v.id] * 100) / 100
        if(_recommendations[v.id] < 0.05){
          delete _recommendations[v.id];
        }

        console.log('now', _recommendations)
      })
    })
  }

};


module.exports = Radio;
