var React = require('react');
var Player = require('./Player');
var Playlist = require('./Playlist');
var PLAY_MODES = require('../constants/AppConstants').playModes;
var _ = require('underscore');
var Radio = require('../utils/Radio');

var page = require('page');

function arePlaylistsTheSame(pl1, pl2){
  if((pl1 && !pl2) || (!pl1 && pl2)) return false;
  if(pl1.length !== pl2.length) return false;
  for(var i = 0; i< pl1.length; i++){
    if(pl1[i].id !== pl2[i].id) return false;
  }
  return true;
}

var PlayerComponent = React.createClass({
  getInitialState: function() {
    return {
      playing: false,
      playlistToggled: true
    };
  },

  componentWillMount: function(){
    this.played = [];
    this.isPlayerShowing = true;
  },

  componentDidMount: function(){
    var that = this;

    window.addEventListener('enqueue', function(e){
      var playlist = that.props.playlist;

      if(e.detail.videos){
        if(e.detail.reset){
          playlist = e.detail.videos;
        } else {
          playlist = playlist.concat(e.detail.videos);
        }
      } else {
        playlist.push(e.detail.video);
      }

      var pos = that.props.position;
      if(e.detail.reset){
        that.props.setPlaylistChange({playlist: playlist, position: 0});
      } else if(pos < playlist.length && !that.state.playing){
        that.props.setPlaylistChange({playlist: playlist, position: pos + 1});
      } else {
        that.props.setPlaylistChange({playlist: playlist});
      }

    }, false);

    window.addEventListener('playNow', function(e){
      var playlist = that.props.playlist.slice(0),
          currPos = that.props.position;

      playlist.splice(currPos + 1, 0, e.detail.video);

      that.props.setPlaylistChange({
        playlist: playlist,
        position: currPos + 1
      });

      //that.playVideoByPos(currPos + 1);
    }, false);
  },

  componentDidUpdate: function(oldProps){
    if(this.props.position !== oldProps.position
      && this.props.position > -1
      && !arePlaylistsTheSame(this.props.playlist, oldProps.playlist)
      && oldProps.playlist.length
      && this.props.playlist[this.props.position]
      && oldProps.playlist[oldProps.position]
      && this.props.playlist[this.props.position].id === oldProps.playlist[oldProps.position].id){
      // case where you delete a video that has already passed.
      // position changes, but id stays the same, do nothing
      return;
    }

    if(this.props.position !== oldProps.position ||
      (!oldProps.playlist[this.props.position] 
        && this.props.playlist[this.props.position])|| 
      (this.props.playlist[this.props.position] &&
        this.props.playlist[this.props.position].id !== oldProps.playlist[this.props.position].id)){
      this.playVideoByPos(this.props.position);
    }
  },

  // --- handlers

  handlePlaylistAdd: function(newPlaylist){
    var pos = this.props.position;
    if(pos < newPlaylist.length && !this.state.playing){
      this.props.setPlaylistChange({position: pos + 1});
    }
  },

  handleVideoPlaying: function(){
    var pos = this.props.position,
        pl = this.props.playlist;

    if(this.props.radio){
      if(pl[pos].source === 'youtube'){
        Radio.recordVideoPlayed(pl[pos], pl, this.props.hdOnly);
      }

      if(pos === pl.length - 1){
        this.props.addNewSong();
      }
    }

    console.log('handleVideoPlaying called', this.props.radio , pos , pl.length);

    document.title =  pl[pos].snippet.title + ' \u266B ' + window.app.baseTitle;

    this.props.videoPlaying();

  },

  handleVideoPaused(){
    this.props.videoPaused();
  },

  handleVideoEnded: function(props){
    var oldPos = this.props.position,
        pos = this.getNextSong(),
        pl = this.props.playlist;

    if(props && props.skipped){
      // used click the go to next track button
      Radio.removeTrackFromRecommendations(pl[oldPos], this.props.hdOnly);
    }

    if(pos > -1){
      // next video go!
      if(pos != oldPos){
        this.props.setPlaylistChange({position: pos});
      } else {
        // shitty edge case of restarting a video
        window.dispatchEvent(new CustomEvent('restartVideo'));
      }
    } else {
      // no video, stop
      this.setState({playing: false});
      document.title = window.app.baseTitle;
    }
  },

  handlePlayNow: function(pos, video){
    var oldPos = this.props.position;

    if(pos !== this.props.position && oldPos > -1){
      //check video position and then remove if not finished?
      if(this.refs.player.getPlayingPosition() < .5 && this.props.playlist[oldPos]){
        Radio.removeTrackFromRecommendations(this.props.playlist[oldPos], this.props.hdOnly);
      }

      this.props.setPlaylistChange({position: pos});

    } else if(pos === this.props.position){
      // clicked on the currently playing video
      window.dispatchEvent(new CustomEvent('restartVideo'));
    } else {
      this.props.setPlaylistChange({position: pos});
    }
  },

  handleDeleteEntry: function(pos, video){
    var pl = this.props.playlist.slice(0),
        currPos = this.props.position;

    // if deleting from history, decrease the position of the playing video on the playlist
    if(pos < currPos){
      currPos -= 1;
    }

    // remove the element from the playlist
    pl.splice(pos, 1);

    if(this.played.indexOf(video.id) > -1){
      var playedIndex = this.played.indexOf(video.id);
      this.played.splice(playedIndex, 1);
    }

    this.props.setPlaylistChange({
      playlist: pl,
      position: currPos
    });
  },

  handleRepeatClick: function(){
    if(this.props.repeatMode === PLAY_MODES.repeat.all){
      this.props.setRepeatMode(PLAY_MODES.repeat.off);
    } else {
      this.props.setRepeatMode(PLAY_MODES.repeat.all);
    }
  },

  handleShuffleClick: function(){
    var newShuffle = !this.props.shuffleActive;
    this.props.setShuffleActive(newShuffle);
  },

  handleNextSong: function(){
    this.handleVideoEnded({skipped: true});
  },

  handlePrevSong: function(){
    if(this.played.length > 1 && this.props.shuffleActive){
      // removing current & prev because prev will be added again!!
      this.played.pop();
      var prevTrackId = this.played.pop(),
          prevTrack = _.findWhere(this.props.playlist, {id: prevTrackId}),
          pos = this.props.playlist.indexOf(prevTrack);

      this.props.setPlaylistChange({position: pos});
    } else if(
      !this.props.shuffleActive &&
      this.props.position > 0
    ){
      this.props.setPlaylistChange({position: this.props.position - 1});
    }
  },

  handleTogglePlayer: function(){
    this.isPlayerShowing = !this.isPlayerShowing;

    if(!this.isPlayerShowing){
      // hide player, shut up
    } else {
      // show player
    }

  },

  handleRadioClick: function(){
    if(!this.props.radio){
      // just enabled it
      var that = this;
      var lastVideoPlaying = this.props.position === this.props.playlist.length - 1;
      Radio.recordVideoPlayed(this.props.playlist[this.props.position], this.props.playlist, this.props.hdOnly,
        function(){
          if(lastVideoPlaying) that.props.addNewSong();
        });
    } else {
      // just disabled it
      Radio.reset();
    }

    this.props.toggleRadioMode();
  },

  handleClearPlaylist: function(){
    var current = this.props.playlist[this.props.position];
    this.props.setPlaylistChange({playlist: [current], position: 0});
    this.played = [];
  },

  handleCreateNew: function(){
    page('/');
  },

  // ----

  getNextSong: function(){
    if(this.props.shuffleActive){
      if(this.played.length === this.props.playlist.length){
        // already played all videos on current playlist
        if(this.props.repeatMode === PLAY_MODES.repeat.all){
          // restart
          this.played = [];
        } else {
          // stop
          return -1;
        }
      }

      var pos, videoId;
      do {
        pos = Math.floor(Math.random() * this.props.playlist.length);
        videoId = this.props.playlist[pos].id;
      } while(this.played.indexOf(videoId) > -1);

      console.log('gonna return', pos, this.played);

      return pos;

    } else {
      var nextPos = this.props.position + 1;
      if(nextPos < this.props.playlist.length){
        // go to next video
        return nextPos;

      } else if(this.props.repeatMode === PLAY_MODES.repeat.all){
        // go to first video
        return 0;

      } else {
        // stop
        return -1;

      }
    }

    if (this.props.repeatAll) {
      if (pl.length==1) { // One video in playlist
        window.dispatchEvent(new CustomEvent('restartVideo'));
      } else {
        this.setState({videoId: pl[0]});
        this.props.setPlaylistChange({position: 0});
      }
    }

  },

  thereIsNext: function(){
    // no songs in playlist - no next one
    if(!this.props.playlist || !this.props.playlist.length){ return false; }

    // repeat all mode always has a next song
    if(this.props.repeatMode === PLAY_MODES.repeat.all){ return true; }

    // is shuffle is on, look at the number of played songs
    if(this.props.shuffleActive){
      return this.played.length !== this.props.playlist.length;
    }

    // otherwise, we're playing sequencially
    if(this.props.position === this.props.playlist.length - 1){ return false; }

    return true;
  },

  thereIsPrev: function(){
    return this.props.position > 0 || (this.props.shuffleActive && this.played.length > 2);
  },

  toggleFullPlaylist: function(){
    if(this.refs.playlistToggle.classList.contains('disabled')) return;
    var p = !this.state.playlistToggled;
    this.setState({playlistToggled: p});
  },

  playVideoByPos: function(pos){
    if(pos >= 0 && this.props.playlist && this.props.playlist[pos]){
      if(this.state.video
        && this.props.playlist[pos]
        && this.state.video.id === this.props.playlist[pos].id){
        window.dispatchEvent(new CustomEvent('restartVideo'));
      } else {
        this.setState({
          video: this.props.playlist[pos],
          playing: true
        });
      }

      this.played.push(this.props.playlist[pos].id);
    } else {
      this.setState({
        video: undefined,
        playing: false
      });

      this.played = [];
    }
  },

  // ------
  // ------ f
  // xxxxxx
  // ------
  // ------ t
  //        (f)
  // ------
  switchPlaylistItems: function(fromIndex, toIndex){
    var pl = this.props.playlist.slice(0),
        moving = this.props.playlist[fromIndex],
        newPos = this.props.position,
        fromIndex = +fromIndex,
        toIndex = +toIndex;

    if(fromIndex === toIndex) return;

    var newToIndex = (toIndex < fromIndex) ? +toIndex + 1 : toIndex;

    var from = pl.splice(fromIndex, 1)[0];
    pl.splice(newToIndex, 0, from);

    if (this.props.position === fromIndex){
      newPos = newToIndex;
    } else if(fromIndex < this.props.position && this.props.position <= toIndex){
      newPos--;
    } else if(fromIndex > this.props.position && this.props.position > toIndex){
      newPos++;
    }

    this.props.setPlaylistChange({
      playlist: pl,
      position: newPos
    });

  },

  noop: function(){},

  render: function() {
    var btnToggleClassName = 'playlist-toggle flat ' + (!this.props.position ? 'disabled' : '');
    var icoClassName = this.state.playlistToggled ? 'fa fa-chevron-up' : 'fa fa-chevron-down';

    return (
      <div>
        <Player
          ref="player"
          autoplay="true"
          paused={this.props.paused}
          video={this.state.video}
          position={this.props.position}
          ts={this.props.ts}
          dts={this.props.dts}
          playing={this.handleVideoPlaying}
          stopped={this.handleVideoPaused}
          ended={this.handleVideoEnded}
          switchPlaylistItems={this.props.switchPlaylistItems}
          playerReady={this.props.onPlayerReady} />

        <div className={"playlist-toolbar" + (!this.props.playlist || !this.props.playlist.length ? " hide" : "")}>
          <div className="btn-group pull-left">
            <button className={(!this.thereIsPrev()) ? 'disabled' : ''} onClick={this.handlePrevSong} data-tooltip='Previous song'>
              <i className="fa fa-backward"></i>
            </button>

            <button className={(!this.thereIsNext()) ? 'disabled' : ''} onClick={this.handleNextSong} data-tooltip='Next song'>
              <i className="fa fa-forward"></i>
            </button>
          </div>

          <div className="btn-group pull-left">
            { /*
            <button className={'tooltip-left'} onClick={this.handleClearPlaylist} data-tooltip='Removes all but the currently playing song'>
              Trim
            </button>
            */ }
          </div>

          <div className="btn-group pull-left">
            <button className={'tooltip-left'} onClick={this.handleClearPlaylist} data-tooltip='Removes all but the currently playing song'>
              Clear
            </button>
          </div>

          <div className="btn-group pull-right">
            <button className={'tooltip-right ' + (this.props.shuffleActive ? 'active' : '')} onClick={this.handleShuffleClick} data-tooltip='Toggle shuffle'>
              <i className="fa fa-random"></i>
            </button>

            <button className={'tooltip-right ' + (this.props.repeatMode === PLAY_MODES.repeat.all ? 'active' : '')} onClick={this.handleRepeatClick} data-tooltip='Toggle repeat'>
              <i className="fa fa-redo-alt"></i>
            </button>
          </div>

          <div className="btn-group pull-right">
            <button className={'tooltip-big tooltip-right ' + (this.props.radio ? 'active' : '')} onClick={this.handleRadioClick} data-tooltip='Automatically adds new recommended videos based on the ones you&apos;ve watched!'>
              <i className="fa fa-music"></i>  Autoplay
            </button>
          </div>

          <button className={btnToggleClassName} ref="playlistToggle" onClick={this.toggleFullPlaylist}>
            <i className={icoClassName}></i>
          </button>
        </div>

        <Playlist
          playlist={this.props.playlist}
          position={this.props.position}
          playlistToggled={this.state.playlistToggled}
          switchPlaylistItems={this.switchPlaylistItems}
          handleDeleteEntry={this.handleDeleteEntry}
          handlePlayNow={this.handlePlayNow} />

      </div>
    );

  }

});

/*
          <div className="btn-group pull-left">
            <button className={''} onClick={this.handleTogglePlayer} data-tooltip='Hide the player'>
              <i className="fa fa-compress"></i>
            </button>
          </div>
*/


module.exports = PlayerComponent;
