/**
 *   Enter your youtube and soundcloud api keys here!
 *   **/

module.exports = {
  youtubeKey: 'AIzaSyDLwX06yG_73ImDEubOb5Yv0E_U1iIdTJs',
  soundcloudKey: '6bc7e6199675a8daa2b68926fcd32576',
  importerKey: 'secret123',
  spotifyClientId: 'e7bdfdf124bf4e1092e183fa9993b34e',
  googleClientId: '67604798339-6h0jpugspf719een0ojibld8trr02cmp.apps.googleusercontent.com'
};
