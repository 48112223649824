var React = require("react");

var FooterComponent = React.createClass({
  render: function () {
    let currYear = new Date().getFullYear();
    return (
      <footer className="footer">
        © 2014, {currYear} Great DJ - Feedback, problems, questions: email us at{" "}
        <a href="mailto:info@great.dj">info@great.dj</a>.
        <a
          href="/privacy-policy.html"
          target="_blank"
          className="privacy-policy-link"
          style={{ color: "#aaa", marginLeft: "4px" }}
        >
          Privacy Policy
        </a>
        <span
          className=""
          style={{
            float: "right",
            fontSize: "0.9em",
            verticalAlign: "middle",
            display: "inline-block",
          }}
        >
          <a
            href="https://twitter.com/intent/follow?original_referer=https%3A%2F%2Fgreat.dj%2F&ref_src=twsrc%5Etfw&region=follow_link&screen_name=greatdjapp&tw_p=followbutton"
            title="Follow @greatdj on Twitter"
            className="twitter-follow-button"
            target="_blank"
          >
            <i></i>Follow @greatdjapp
          </a>
        </span>
      </footer>
    );
  },
});

module.exports = FooterComponent;
