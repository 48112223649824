var Api = require("../utils/Api");
var Radio = require("../utils/Radio");
var Constants = require("../constants/AppConstants");
var AppDispatcher = require("../dispatcher/AppDispatcher");
var cookie = require("cookie");
var { toast } = require("react-toastify");

var page = require("page");

var PlaylistActions = {
  /**
   * @param  {string} text
   */
  save: function (pl, plId, fn) {
    AppDispatcher.handleViewAction({
      actionType: Constants.PLAYLIST_SAVE,
    });

    Api.savePlaylist(pl, plId, fn);
  },

  /**
   * @param  {string} text
   */
  createAndSync: function (pl, pos) {
    AppDispatcher.handleViewAction({
      actionType: Constants.PLAYLIST_SAVE,
    });

    Api.savePlaylist(pl, null, function (newPl, data) {
      PlaylistActions.sync(data.id, pl, pos);
    });
  },

  /**
   * @param  {string} id
   */
  load: function (plId, version, preventRedirect, fn) {
    if (Api.io.socket) {
      // ...
      this.unsync();
    }

    Radio.reset();
    Api.loadPlaylist(plId, version, preventRedirect, fn);
  },

  clear() {
    AppDispatcher.handleViewAction({
      actionType: Constants.PLAYLIST_CLEAR_CURRENT,
    });
  },

  sync: function (plId, pl, pos) {
    console.log("sync", plId);
    AppDispatcher.handleViewAction({
      actionType: Constants.SYNC_ON,
    });

    Api.io.register(plId, pl, pos);
  },

  unsync: function () {
    console.log("unsync");
    Api.io.unregister();

    AppDispatcher.handleViewAction({
      actionType: Constants.SYNC_OFF,
    });
  },

  changedPlaylist: function (id, changes, sync) {
    AppDispatcher.handleViewAction({
      actionType: Constants.PLAYLIST_CHANGE,
      response: {
        playlistId: id,
        changes: changes,
      },
    });

    if (
      sync &&
      changes.position !== -1 &&
      (changes.playlist || changes.position !== undefined)
    ) {
      console.log("sending changedPlaylist", id, changes);
      Api.io.changedPlaylist(id, changes.playlist, changes.position);
    }
  },

  unsetPlaylistId: function () {
    AppDispatcher.handleViewAction({
      actionType: Constants.UNSET_PLAYLIST_ID,
    });

    PlaylistActions.unsync();
  },

  getPopularPlaylists: function () {
    Api.getPopularPlaylists();
  },

  updatePlaylistSettings: function (id, hash, value, key) {
    var newConfig = {};
    newConfig[key] = value;
    Api.updatePlaylistSettings(id, hash, newConfig);
    AppDispatcher.handleViewAction({
      actionType: Constants.UPDATE_PLAYLIST_SETTINGS,
      id,
      key,
      value,
    });
  },

  updateUrl: function (id, newId, hash) {
    newId = newId.replace(/[\s]+/g, "-").substring(0, 50);
    if (id === newId) return;

    Api.updatePlaylistId(id, hash, newId);

    history.replaceState(null, null, "/" + newId + window.location.hash);

    this.addPlaylistToCookie(id, hash, newId);

    AppDispatcher.handleViewAction({
      actionType: Constants.UPDATE_PLAYLIST_SETTINGS,
      id,
      key: "id",
      value: newId,
    });
  },

  addTag: function (tag, id, uuid) {
    Api.tagActionForPlaylist(id, tag, uuid, "add");
  },

  downvoteTag: function (tag, id, uuid) {
    Api.tagActionForPlaylist(id, tag, uuid, "remove");
  },

  refreshPlaylistImage: function (id, hash) {
    Api.refreshPlaylistImage(id, hash);
  },

  reset: function () {
    this.unsetPlaylistId();
    Radio.reset();
    AppDispatcher.handleViewAction({
      actionType: Constants.RESET,
    });

    document.title = window.app.baseTitle;
  },

  addPlaylistToCookie: function (id, hash, newId) {
    AppDispatcher.handleViewAction({
      actionType: Constants.UPDATE_MY_PLAYLISTS,
      id,
      hash,
      newId,
    });
  },

  describeMyPlaylists: function (ids) {
    Api.getPlaylistsSummary(ids);
  },

  setUserToMyPlaylists: function (playlists) {
    Api.setUserToMyPlaylists(playlists, () => {
      Api.getOwnPlaylists();
    });
  },

  setMyPlaylists: function (playlists) {
    AppDispatcher.handleViewAction({
      actionType: Constants.SET_MY_PLAYLISTS,
      playlists,
    });
  },

  resetCookie: function () {
    AppDispatcher.handleViewAction({
      actionType: Constants.RESET_COOKIE,
    });
  },

  getOwnPlaylists: function () {
    Api.getOwnPlaylists();
  },

  clearMyPlaylists: function () {
    AppDispatcher.handleViewAction({
      actionType: Constants.CLEAR_MY_PLAYLISTS,
    });
  },

  sendPauseEvent() {
    Api.io.sendPauseEvent();
  },

  sendPlayEvent() {
    Api.io.sendPlayEvent();
  },

  delete(id) {
    Api.deletePlaylist(id, () => {
      Api.getOwnPlaylists();
      Api.getPopularPlaylists();
    });
    toast("Playlist deleted.", {
      position: toast.POSITION.TOP_CENTER,
    });
    page.redirect("/");
  },
};

module.exports = PlaylistActions;
