var React = require('react');
var IntroComponent = require('./IntroComponent');
var SearchResults = require('./SearchResults');
var ChatComponent = require('./ChatComponent');

var SearchComponent = React.createClass({

  videoEnqueued: function(video){
    var evt = new CustomEvent('enqueue',
      {'detail': {
        'video': video,
      }
    });
    window.dispatchEvent(evt);
  },

  playlistEnqueued: function(videos){
    var evt = new CustomEvent('enqueue',
      {'detail': {
        'videos': videos,
        'reset': true
      }
    });
    window.dispatchEvent(evt);
  },

  addAllResults: function(videos){
    var evt = new CustomEvent('enqueue',
      {'detail': {
        'videos': videos,
        'reset': false
      }
    });
    window.dispatchEvent(evt);
  },

  playNowHandler: function(video){
    var evt = new CustomEvent('playNow',
      {'detail': {
        'video': video,
      }
    });
    window.dispatchEvent(evt);
  },

  render: function() {
    var mainView = this.getMainView();

    return (
      <div>
        {mainView}

        {
          this.props.sync ? 
           <ChatComponent 
            messages={this.props.messages}
            playlistId={this.props.playlistId}
          /> : ''
        }
      </div>
    );
  },

  getMainView: function(){
    if(this.props.results && this.props.results.length){
      return (
        <SearchResults
          videos={this.props.results}
          enqueueHandler={this.videoEnqueued}
          playlistEnqueueHandler={this.playlistEnqueued}
          playNowHandler={this.playNowHandler}
          addAllResultsHandler={this.addAllResults}
          recentTerms={this.props.recentTerms}
          popularPlaylists={this.props.popularPlaylists}
          setPosition={this.props.setPosition}
          getPlaylistPosition={this.props.getPlaylistPosition}
          loadPlaylist={this.props.loadPlaylist}
          setQuery={this.props.setQuery}
          myPlaylists={this.props.myPlaylists}
          myPlaylistsSummary={this.props.myPlaylistsSummary}
          user={this.props.user}
          sync={this.props.sync}
        />
      );
    } else if(!this.props.sync) {
      return (
        <IntroComponent
          isPlaylistLoaded={!!this.props.playlistId}
          recentTerms={this.props.recentTerms}
          popularPlaylists={this.props.popularPlaylists}
          setPosition={this.props.setPosition}
          loadPlaylist={this.props.loadPlaylist}
          setQuery={this.props.setQuery}
          myPlaylists={this.props.myPlaylists}
          myPlaylistsSummary={this.props.myPlaylistsSummary}
          user={this.props.user}
         />
      )
    } 

  }
});

module.exports = SearchComponent;

