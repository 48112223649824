const React = require("react");
const ShareModal = require("./ShareModal");
const PlaylistActions = require("../actions/PlaylistActions");
const _ = require("underscore");
const Api = require("../utils/Api");

var PlaylistConfig = React.createClass({
  getInitialState: function () {
    var shouldEditTitle =
      this.props &&
      (!this.props.playlistSettings || !this.props.playlistSettings.title);

    return {
      editingEnabled: false,
      showShareModal: false,
      editingTitle: shouldEditTitle,
      editingUrl: false,
      editingLink: false,
      editingDescription: false,

      idAvailable: true,

      addingTag: false,
      shouldSelectTags: false,

      shouldSelectTitle: shouldEditTitle,
      shouldSelectUrl: false,

      votedTags: [],

      message: "",

      unread: 0,
    };
  },

  componentWillMount: function () {
    this.checkIdAvailableThrottled = _.throttle(this.checkIdAvailable, 500);

    // playlist
    this.keysToRefs = {
      title: "playlistTitle",
      link: "playlistLink",
      description: "playlistDescription",
    };
  },

  componentDidUpdate: function (oldprops, oldstate) {
    if (this.state.shouldSelectTitle && this.refs.playlistTitle) {
      this.refs.playlistTitle.select();
      this.setState({ shouldSelectTitle: false });
    }

    if (this.state.shouldSelectUrl && this.refs.playlistId) {
      this.refs.playlistId.select();
      this.setState({ shouldSelectUrl: false });
      this.checkIdAvailable(this.props.playlistId);
    }

    if (this.state.shouldSelectTags && this.refs.tagInput) {
      this.refs.tagInput.focus();
    }
  },

  componentWillReceiveProps: function (props) {
    if (props.playlistSettings && !props.playlistSettings.title) {
      this.setState({
        editingTitle: true,
        shouldSelectTitle: true,
      });
    }
  },

  componentDidMount() {
    /*
    this.handleMouseMove = this._handleMouseMove;
    this.handleMouseOut = this._handleMouseOut;
    window.addEventListener('mousemove', this.handleMouseMove);
    */
  },

  componentWillUnmount() {
    //window.removeEventListener('mousemove', this.handleMouseMove);
  },

  _handleMouseMove: function (e) {
    let mx = e.pageX;
    let my = e.pageY;
    let rect = this.refs.background.getBoundingClientRect();

    if (
      mx > rect.right ||
      my > rect.bottom ||
      my < rect.top ||
      mx < rect.left
    ) {
      if (this.posDirty) this.handleMouseOut();
      return;
    }

    this.posDirty = true;
    this.refs.background.style.transition = "";

    let x = Math.round(
      ((mx - (rect.left + (rect.width * 0.65) / 2)) /
        ((rect.width * 0.65) / 2)) *
        8
    );
    let y = Math.round(
      ((my - (rect.top + rect.height / 2)) / (rect.height / 2)) * 3
    );

    this.refs.background.style.backgroundPositionX = 75 + x + "%";
    this.refs.background.style.backgroundPositionY = 50 + y + "%";
    //console.log('e', x , y);
  },

  _handleMouseOut: function () {
    this.refs.background.style.transition = "background-position 250ms ease-in";
    this.refs.background.style.backgroundPositionX = "75%";
    this.refs.background.style.backgroundPositionY = "50%";
    this.posDirty = false;
  },

  // modal stuff
  handleShare: function (e) {
    e.stopPropagation();
    if (e.currentTarget.classList.contains("disabled")) return;
    this.setState({ showShareModal: true });
  },

  handleDelete() {
    PlaylistActions.delete(this.props.playlistId);
  },

  modalHidden: function () {
    this.setState({ showShareModal: false });
  },

  // url:
  handleSaveNewPlaylistUrl: function () {
    if (!this.state.idAvailable) return;
    var newId;

    if (this.props.playlistId.split("/").length === 2 && this.props.user) {
      newId =
        this.props.user.username +
        "/" +
        this.refs.playlistId.value.trim().replace("/", "");
    } else {
      newId = this.refs.playlistId.value.trim();
    }

    PlaylistActions.updateUrl(
      this.props.playlistId,
      newId,
      this.props.playlistHash
    );

    this.setState({ editingUrl: false });
  },

  handleCancelPlaylistUrl: function () {
    this.refs.playlistId.value = this.props.playlistId;
    this.setState({ editingUrl: false, shouldSelectUrl: false });
  },

  handleKeyUpOnUrlField: function (e) {
    if (e.which === 13) {
      this.handleSaveNewPlaylistUrl();
    } else if (e.which === 27) {
      this.handleCancelPlaylistUrl();
    } else {
      // we should check if id is available
      var id = this.refs.playlistId.value.trim();
      this.checkIdAvailableThrottled(id);
    }
  },

  // tags :
  showAddFormTag: function (e) {
    e.preventDefault();
    this.toggleStateProperty(["addingTag", "shouldSelectTags"]);
  },

  handleSubmitTag: function () {
    PlaylistActions.addTag(
      this.refs.tagInput.value,
      this.props.playlistId,
      this.props.uuid
    );
    this.setState({ addingTag: false });
  },

  handleDownvoteTag: function (e) {
    var tag = e.target.parentElement.parentElement.attributes["data-tag"].value;
    PlaylistActions.downvoteTag(tag, this.props.playlistId, this.props.uuid);

    this.setState({
      votedTags: this.state.votedTags.concat(tag),
    });
  },

  handleUpvoteTag: function (e) {
    var tag = e.target.parentElement.parentElement.attributes["data-tag"].value;
    PlaylistActions.addTag(tag, this.props.playlistId, this.props.uuid);

    this.setState({
      votedTags: this.state.votedTags.concat(tag),
    });
  },

  handleKeyUpOnTagField: function (e) {
    if (e.which === 13) {
      this.handleSubmitTag();
    } else if (e.which === 27) {
      this.toggleStateProperty("addingTag");
    }
  },

  //
  handleRefreshImage: function () {
    PlaylistActions.refreshPlaylistImage(
      this.props.playlistId,
      this.props.playlistHash
    );
  },

  handleEditingLink: function (e) {
    if (!this.props.isMine) return;
    e.preventDefault();
    this.toggleStateProperty("editingLink");
  },

  checkIdAvailable: function (id) {
    var that = this;
    var hasUser = this.props.playlistId.split("/").length === 2;
    var newId =
      hasUser && this.props.user ? this.props.user.username + "/" + id : id;

    if (id === this.props.playlistId) {
      this.setState({ idAvailable: true });
      return;
    } else if (!id || id === "playlists") {
      this.setState({ idAvailable: false });
      return;
    }

    Api.checkForId(newId, function (result) {
      that.setState({ idAvailable: result });
    });
  },

  filterSpaces: function (e) {
    return e.which !== 32;
  },

  renderPlaylistConfig: function () {
    var settings = this.props.playlistSettings;
    var mine = this.props.isMine;

    var playlistUserId = this.getPlaylistIdAndUser(this.props.playlistId);

    settings.title =
      settings.title && settings.title.trim()
        ? settings.title
        : "New Playlist: " + this.props.playlistId;

    let content;
    if (!this.props.sync) {
      content = (
        <div className="left-panel">
          <div className="img-container" onClick={this.handleRefreshImage}>
            <div
              className={
                "img-container-inside " + (mine ? "editable-image" : "")
              }
            >
              <img className="playlist-image" src={settings.thumbnail} />
            </div>
          </div>
          <div className="left-panel-contents">
            <div className="left-panel-top">
              <div className="left-panel-title">
                {mine && this.state.editingTitle ? (
                  this.renderEditableTitle(settings.title)
                ) : (
                  <h2
                    className={mine ? "editable" : ""}
                    onClick={this.toggleStateProperty.bind(
                      this,
                      ["editingTitle", "shouldSelectTitle"],
                      true
                    )}
                  >
                    <span className="with-border">{settings.title}</span>
                  </h2>
                )}
              </div>
              {mine && this.state.editingDescription
                ? this.renderEditableDescription()
                : this.renderDescription()}
              <div className="left-panel-url">
                {mine && this.state.editingUrl ? (
                  this.renderEditableUrl()
                ) : (
                  <h3
                    className={(mine ? "editable" : "") + " url-subtitle"}
                    onClick={this.toggleStateProperty.bind(this, [
                      "editingUrl",
                      "shouldSelectUrl",
                    ])}
                  >
                    {`https://great.dj/${
                      playlistUserId.user ? playlistUserId.user + "/" : ""
                    }`}
                    <span className="with-border">{playlistUserId.id}</span>
                  </h3>
                )}
              </div>
              <div className="left-panel-user">
                {this.props.playlistUsername ? (
                  <h3>
                    A{this.props.playlistSettings.private ? " " : "n open "}
                    playlist by{" "}
                    <a href={"/playlists/" + this.props.playlistUsername}>
                      {this.props.playlistUsername}
                    </a>
                    .
                  </h3>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="left-panel-bottom">
              <div className="left-panel-buttons">
                <p>
                  <button
                    className="share-button"
                    onClick={this.handleShare}
                    style={{ marginLeft: 0 }}
                  >
                    <i className="fa fa-share-alt"></i> Share
                  </button>
                </p>
                {mine && this.props.user ? this.renderDeleteButton() : ""}
                {mine && this.props.user ? this.renderPrivateCheck() : ""}
                {/*
                <p>
                  <label htmlFor="autoSaveCheck">Autosave</label>
                  <span className="value"><input id="autoSaveCheck" type="checkbox" checked={this.props.autoSave} onClick={this.props.toggleAutoSave} /></span>
                </p>
          */}
                <p className="subtitle">
                  {this.props.playlistSize} tracks <br />{" "}
                  {settings.views ? +settings.views : 1} views <br /> Created:{" "}
                  {new Date(Date.parse(settings.created)).toDateString()}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      content = (
        <div className="left-panel" style={{ textAlign: "center" }}>
          <h2 style={{ marginTop: 0 }}>You're now connected in Party Mode!</h2>
          <p>
            In Party Mode you can have several people and devices controlling
            the same playlist at the same time.
          </p>
          <p style={{ userSelect: "none" }}>
            Share this URL with your friends
            <span
              style={{
                fontFamily: "monospace",
                background: "rgba(255,255,255,0.33)",
                padding: 4,
                borderRadius: 4,
                margin: "0 4px",
                userSelect: "all",
              }}
            >
              {`${window.location.origin}/${this.props.playlistId}/?party=true`}
            </span>
            and you'll all be connected and kept in sync!
          </p>
          {this.props.partyClients === 1 ? (
            <p>
              You are currently the only one here, invite more people to join.
            </p>
          ) : (
            <p>
              There are currently {this.props.partyClients} users connected to
              this playlist.
            </p>
          )}
          <p style={{ color: "rgba(255,255,255,0.6)" }}>
            TIP: Changes made in Party Mode are not automatically saved so be
            sure to press Save at the end if you want to keep the final version
            of your playlist.
          </p>
        </div>
      );
    }

    return (
      <div className="panel">
        <div
          className="panel-bg"
          style={{ backgroundImage: "url(" + settings.thumbnail + ")" }}
          ref="background"
        ></div>

        {content}

        <ShareModal
          playlistId={this.props.playlistId}
          playlistVersion={this.props.playlistVersion}
          showing={this.state.showShareModal}
          hidden={this.modalHidden}
          shown={this.noop}
        />
      </div>
    );

    /* tags stuff:
      <ul className="tags">
        { this.renderPlaylistTags(settings.tags, mine) }
      </ul>
      { this.state.addingTag ?
          this.renderAddTagInput() :
          <a href="#" onClick={this.showAddFormTag} className="add-tag tooltip-right" data-tooltip="Suggest a new tag">+</a>
      }
      */
  },

  renderDeleteButton() {
    return (
      <button
        className="delete-button"
        onClick={this.handleDelete}
        style={{ marginLeft: 0 }}
      >
        <i className="fa fa-trash-alt"></i> Delete
      </button>
    );
  },

  renderPrivateCheck() {
    return (
      <p>
        <label htmlFor="privateCheck">Open (anyone can edit)</label>
        <span className="value">
          <input
            id="privateCheck"
            type="checkbox"
            checked={!this.props.playlistSettings.private}
            onClick={this.props.togglePrivate}
          />
        </span>
      </p>
    );
  },

  renderEditableTitle: function (title) {
    var submitFn = this.handleUpdatePlaylistField.bind(
      this,
      "title",
      "editingTitle"
    );

    return (
      <span className="title-input-container">
        <input
          type="text"
          ref="playlistTitle"
          defaultValue={title}
          onKeyUp={this.handleKeyUpTextField.bind(this, submitFn)}
          maxLength="45"
        />
        <button type="button" onClick={submitFn}>
          Save
        </button>
        <button
          type="button"
          onClick={() => {
            this.toggleStateProperty("editingTitle");
          }}
        >
          Cancel
        </button>
      </span>
    );
  },

  renderEditableUrl: function () {
    var submitFn = this.handleSaveNewPlaylistUrl;
    var idSplit = this.getPlaylistIdAndUser(this.props.playlistId);

    return (
      <span
        className={
          "id-field " + (this.state.idAvailable ? "available" : "taken")
        }
      >
        <h3>
          {"https://great.dj/" + (idSplit.user ? idSplit.user + "/" : "")}
        </h3>
        <input
          type="text"
          maxLength="35"
          className="input-short"
          ref="playlistId"
          defaultValue={idSplit.id}
          onKeyUp={(e) => {
            this.handleKeyUpOnUrlField(e, idSplit.user);
          }}
          onKeyDown={this.filterSpaces}
        />
        <button type="button" onClick={submitFn}>
          Save
        </button>
        <button type="button" onClick={this.handleCancelPlaylistUrl}>
          Cancel
        </button>
      </span>
    );
  },

  renderPlaylistTags: function (tagsArray, mine) {
    if (!tagsArray) return;
    return tagsArray.map(function (tag) {
      return (
        <li data-tag={tag} key={tag}>
          <span className="tag">{tag}</span>
          {this.state.votedTags.indexOf(tag) === -1 ? (
            <span
              data-tooltip="Upvote"
              className="icon-container icon-container-up tooltip-up"
              onClick={this.handleUpvoteTag}
            >
              <i className="fa fa-caret-up"></i>
            </span>
          ) : (
            ""
          )}
          {this.state.votedTags.indexOf(tag) === -1 ? (
            <span
              data-tooltip="Downvote"
              className="icon-container icon-container-down tooltip-up"
              onClick={this.handleDownvoteTag}
            >
              <i className="fa fa-caret-down"></i>
            </span>
          ) : (
            ""
          )}
        </li>
      );
    }, this);
  },

  renderAddTagInput: function () {
    return (
      <span className="tags-input-container">
        <input
          type="text"
          maxLength="15"
          ref="tagInput"
          onKeyUp={this.handleKeyUpOnTagField}
          onKeyDown={this.filterSpaces}
        />
        <button type="button" onClick={this.handleSubmitTag}>
          Save
        </button>
        <button
          type="button"
          onClick={() => {
            this.toggleStateProperty("addingTag");
          }}
        >
          Cancel
        </button>
      </span>
    );
  },

  // link stuff
  renderEditableLink: function (e) {
    var submitFn = this.handleUpdatePlaylistField.bind(
      this,
      "link",
      "editingLink"
    );
    return (
      <div className={"left-panel-link"}>
        <span className="link-input-container">
          <input
            type="text"
            ref="playlistLink"
            defaultValue={this.props.playlistSettings.link || "https://"}
            onKeyUp={this.handleKeyUpTextField.bind(this, submitFn)}
          />
          <button type="button" onClick={submitFn}>
            Save
          </button>
          <button
            type="button"
            onClick={() => {
              this.toggleStateProperty("editingLink");
            }}
          >
            Cancel
          </button>
        </span>
      </div>
    );
  },

  renderLink: function (e) {
    if (this.props.playlistSettings.link || this.props.isMine) {
      return (
        <div className="left-panel-link">
          <a
            className={this.props.isMine ? " editable" : ""}
            href={this.props.playlistSettings.link}
            target="_blank"
            onClick={this.handleEditingLink}
          >
            {this.props.playlistSettings.link || "Add an extenal link..."}
          </a>
        </div>
      );
    }
  },

  // description
  renderEditableDescription: function (e) {
    var submitFn = this.handleUpdatePlaylistField.bind(
      this,
      "description",
      "editingDescription"
    );

    return (
      <div className={"left-panel-description"}>
        <span className="link-input-container">
          <textarea ref="playlistDescription">
            {this.props.playlistSettings.description}
          </textarea>
          <button type="button" onClick={submitFn}>
            Save
          </button>
          <button
            type="button"
            onClick={() => {
              this.toggleStateProperty("editingDescription");
            }}
          >
            Cancel
          </button>
        </span>
      </div>
    );
  },

  renderDescription: function (e) {
    if (this.props.playlistSettings.description || this.props.isMine) {
      return (
        <div className="left-panel-description">
          <p
            className={this.props.isMine ? " editable" : ""}
            onClick={this.toggleStateProperty.bind(
              this,
              "editingDescription",
              true
            )}
          >
            {this._wrapLinks(this.props.playlistSettings.description) ||
              "Add a description..."}
          </p>
        </div>
      );
    }
  },

  _wrapLinks(str) {
    if (!str) return str;
    var urlRegexp = /(https?:\/\/[^\s]+)/gi;
    var replaced = str.replace(urlRegexp, (url) => {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });
    return <span dangerouslySetInnerHTML={{ __html: replaced }} />;
  },

  render: function () {
    return (
      <div>
        {this.props.playlistId && this.props.playlistSettings
          ? this.renderPlaylistConfig()
          : ""}
      </div>
    );
  },

  getPlaylistIdAndUser(id) {
    var obj = {};
    var split = id.split("/");
    if (split.length === 2) {
      obj.user = split[0];
      obj.id = split[1];
    } else {
      obj.id = split[0];
    }

    return obj;
  },

  // generic utils
  handleUpdatePlaylistField(key, stateToToggle) {
    var newValue = this.refs[this.keysToRefs[key]].value;

    newValue = newValue.slice(0, 512); // 1024 chars limit for everything

    if ((newValue && newValue.trim()) || key.match(/description|link/)) {
      PlaylistActions.updatePlaylistSettings(
        this.props.playlistId,
        this.props.playlistHash,
        newValue.trim(),
        key
      );

      this.toggleStateProperty(stateToToggle);
    }
  },

  toggleStateProperty: function (prop, auth) {
    if (auth && !this.props.isMine) return;
    var newVal = {};
    if (prop instanceof Array) {
      prop.forEach(function (p) {
        newVal[p] = !this.state[p];
      }, this);
    } else {
      newVal[prop] = !this.state[prop];
    }
    this.setState(newVal);
  },

  handleKeyUpTextField: function (submit, e) {
    if (e.which === 13) {
      submit();
    }
  },
});

module.exports = PlaylistConfig;
