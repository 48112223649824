var React = require("react");

var SearchComponent = require("./SearchComponent");
var PlayerComponent = require("./PlayerComponent");
var PlaylistConfig = require("./PlaylistConfig");
var PlaylistsComponent = require("./PlaylistsComponent");
// var FeedbackComponent = require('./FeedbackComponent'), // @todo ?
var FooterComponent = require("./FooterComponent");
//var Sampler = require('react-sampler');
var Radio = require("../utils/Radio");
var TopBar = require("./TopBar");
var { ToastContainer, Slide } = require("react-toastify");
var LoginModal = require("./LoginModal");

/*
var samples = [{
  file: '/static/audio/horn.mp3',
  key: 'h', // horn
  startAt: 0.2 //seconds, obvs
},{
  file: '/static/audio/orch5.wav',
  key: 'o', // orchestra hit
  startAt: 0.05
},{
  file: '/static/audio/rimshot.mp3',
  key: 's' // rimshot
},{
  file: '/static/audio/cowbell.mp3',
  key: 'c' // cowbell
},{
  file: '/static/audio/gun.mp3',
  key: 'g' // gun shot
},{
  file: '/static/audio/reload.wav',
  key: 'r' // reload!
},{
  file: '/static/audio/machinegun.mp3',
  key: 'm' // machine gun
}];
*/

if (process.env.BROWSER) {
  require("../less/styles.less");
}

var AppMainView = React.createClass({
  getInitialState() {
    return {
      showLoginModal: false,
      onCommand: false,
      command: [],
    };
  },

  componentDidMount() {
    window.addEventListener("keydown", this.handleWindowKey);
  },

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleWindowKey);
  },

  handleWindowKey(e) {
    if (e.target.tagName === "INPUT" || e.target.tagName === "TEXTAREA") {
      return true;
    }

    if (
      e.target.tagName === "LI" &&
      e.parentNode &&
      e.parentNode.parentNode &&
      e.parentNode.parentNode.classList.contains("autocomplete")
    ) {
      return;
    }

    if (e.key === "j" || e.key === "ArrowDown") {
      e.preventDefault();
      let focus = document.activeElement;
      let pos;
      if (focus && focus.getAttribute("data-hook") === "playlist-item") {
        pos = +focus.parentNode.getAttribute("data-pos") + 1;
      } else {
        pos = this.props.position + 1;
      }
      if (pos < this.props.playlist.length) {
        document.querySelector(`li[data-pos="${pos}"] a`).focus();
      }
      return;
    } else if (e.key === "k" || e.key === "ArrowUp") {
      e.preventDefault();
      let focus = document.activeElement;
      let pos;
      if (focus && focus.getAttribute("data-hook") === "playlist-item") {
        pos = +focus.parentNode.getAttribute("data-pos") - 1;
      } else {
        pos = this.props.position - 1;
      }
      if (pos >= 0) {
        document.querySelector(`li[data-pos="${pos}"] a`).focus();
      }
    } else if (e.key === "s" || e.key === "/") {
      e.preventDefault();
      let search = document.querySelector("input.q");
      search.focus();
      search.select();
    }
  },

  componentDidUpdate(prevProps) {
    if (
      prevProps.view !== this.props.view ||
      prevProps.search !== this.props.search ||
      prevProps.playlistId !== this.props.playlistId
    ) {
      window.scrollTo(0, 0);
    }
  },

  render: function () {
    var content;
    if (this.props.view === "playlists") {
      content = (
        <PlaylistsComponent
          playlistSearch={this.props.playlistSearch}
          page={this.props.playlistsPage}
          user={this.props.playlistsUser}
          userDetails={this.props.playlistsUserDetails}
          userError={this.props.playlistsUserError}
          perPage={24}
          loadPlaylist={this.props.loadPlaylist}
          query={this.props.search}
          userNotFound={this.props.userNotFound}
          playlists={this.props.playlists}
          count={this.props.count}
          loggedInUser={this.props.user}
          showLoginModal={this.showLoginModal}
        />
      );
    } else {
      content = (
        <SearchComponent
          results={this.props.results}
          handleSavePlaylist={this.props.handleSavePlaylist}
          mode={this.props.mode}
          playlistId={this.props.playlistId}
          toggleSync={this.props.toggleSync}
          sync={this.props.sync}
          recentTerms={this.props.recentTerms}
          currentQuery={this.props.currentQuery}
          setQuery={this.props.setQuery}
          setHdOnly={this.props.setHdOnly}
          setPosition={this.props.setPosition}
          popularPlaylists={this.props.popularPlaylists}
          partyClients={this.props.sync && this.props.partyClients}
          getPlaylistPosition={this.props.getPlaylistPosition}
          loadPlaylist={this.props.loadPlaylist}
          myPlaylists={this.props.myPlaylists}
          myPlaylistsSummary={this.props.myPlaylistsSummary}
          searching={this.props.searching}
          user={this.props.user}
          view={this.props.view}
          messages={this.props.messages}
        />
      );
    }

    return (
      <div
        className={
          "app-container " + (this.props.sync ? "app-container-sync" : "")
        }
      >
        <div id="playlist-config">
          {this.props.view !== "playlists" && this.props.playlistId
            ? this.renderPlaylistConfig()
            : ""}
        </div>
        <div id="search-component" ref="searchComponent">
          <TopBar
            handleSavePlaylist={this.props.handleSavePlaylist}
            playlistId={this.props.playlistId}
            unsetPlaylistId={this.props.unsetPlaylistId}
            toggleSync={this.props.toggleSync}
            sync={this.props.sync}
            currentQuery={this.props.currentQuery}
            setQuery={this.props.setQuery}
            setHdOnly={this.props.setHdOnly}
            partyClients={this.props.partyClients}
            setPosition={this.props.setPosition}
            resetApp={this.props.resetApp}
            view={this.props.view}
            searching={this.props.searching}
            waitingForPlaylistResult={this.props.waitingForPlaylistResult}
            user={this.props.user}
            logout={this.props.logout}
            showLoginModal={this.showLoginModal}
          />

          {content}
        </div>
        <div id="player-component">
          <PlayerComponent
            playlist={this.props.playlist}
            playlistId={this.props.playlistId}
            playlistVersion={this.props.playlistVersion}
            setShuffleActive={this.props.setShuffleActive}
            shuffleActive={this.props.shuffleActive}
            setRepeatMode={this.props.setRepeatMode}
            repeatMode={this.props.repeatMode}
            position={this.props.position}
            paused={this.props.paused}
            onPlayerReady={this.props.playerReady}
            setPlaylistChange={this.props.setPlaylistChange}
            toggleRadioMode={this.props.toggleRadioMode}
            addNewSong={this.props.addNewSong}
            hdOnly={this.props.hdOnly}
            mode={this.props.mode}
            radio={this.props.radio}
            ts={this.props.ts}
            dts={this.props.dts}
            videoPaused={this.props.videoPaused}
            videoPlaying={this.props.videoPlaying}
          />
        </div>
        {/*
        <Sampler samples={samples} disabled={true} />
        */}
        <FooterComponent />
        <div className="toast-container">
          <ToastContainer
            autoClose={5000}
            hideProgressBar={true}
          ></ToastContainer>
        </div>

        <LoginModal
          showing={this.state.showLoginModal}
          hidden={this.loginModalHidden}
        />
      </div>
    );
  },

  renderPlaylistConfig: function () {
    var mine, playlistHash;

    if (this.props.user) {
      // user based auth
      if (this.props.playlistUser) {
        // playlist has username attached
        if (this.props.user.username === this.props.playlistUser) {
          mine = true;
        }
      }
    } else {
      // anonymous stuff based on cookies
      this.props.myPlaylists.forEach((plArray) => {
        if (plArray[0] === this.props.playlistId) {
          mine = true;
          playlistHash = plArray[1];
        }
      });
    }

    return (
      <PlaylistConfig
        playlistId={this.props.playlistId}
        playlist={this.props.playlist}
        playlistSize={this.props.playlistSize}
        playlistSettings={this.props.playlistSettings}
        playlistVersion={this.props.playlistVersion}
        playlistUsername={this.props.playlistUser}
        autoSave={this.props.autoSave}
        sync={this.props.sync}
        partyClients={this.props.partyClients}
        uuid={this.props.uuid}
        togglePrivate={this.props.togglePrivate}
        toggleAutoSave={this.props.toggleAutoSave}
        isMine={mine}
        playlistHash={playlistHash}
        user={this.props.user}
      />
    );
  },

  showLoginModal: function () {
    this.setState({ showLoginModal: true });
  },

  loginModalHidden: function () {
    this.setState({ showLoginModal: false });
  },
});

/**
  disabled sampler.... need doc in the interface for this somewhere

         <a id="feedback-link" href="#feedback" className="desktop hide" onClick={this.showFeedbackForm}>Send feedback!</a>
        <FeedbackComponent
          show={this.state.showFeedbackForm}
          handleDispose={this.hideFeedbackForm}
        />
  **/

module.exports = AppMainView;
