/* globals process */
const React = require("react");
const PropTypes = require("prop-types");

if (process.env.BROWSER) {
  require("../less/user-details.less");
}

class UserDetails extends React.Component {
  constructor(props) {
    super(props);
    this.handleSpotifyConnectClick = this._handleSpotifyConnectClick.bind(this);
  }

  render() {
    if (this.props.user.err) return this.renderUserError();

    let imageSrc = this.props.user.image
      ? this.props.user.image
      : "/static/images/user_icon.png";

    return (
      <div className="user-details">
        <div style={{ float: "left" }}>
          {imageSrc ? <img src={imageSrc} className="user-avatar" /> : ""}
        </div>
        <div style={{ float: "left", marginLeft: 20 }}>
          <h2 className="user-name">
            {this.props.user.display_name || this.props.user.username}
          </h2>
          <p className="user-playlist-count">
            {this.props.count === undefined
              ? "Loading..."
              : this.props.count === 0
              ? "No playlists yet!"
              : this.props.count +
                " playlist" +
                (this.props.count === 1 ? "" : "s")}
          </p>
        </div>
        {this.props.isMe ? this.renderConnectedAccounts() : ""}
      </div>
    );
  }

  renderUserError() {
    return (
      <div className="user-details">
        <h3 className="user-name">User not found</h3>
      </div>
    );
  }

  renderConnectedAccounts() {
    if (!this.props.user.connectedAccounts) return null;
    let accs = this.props.user.connectedAccounts;

    let labelStyle = {
      fontWeight: "500",
      marginRight: 4,
      fontSize: "0.875em",
      verticalAlign: "top",
    };

    let imgStyle = {
      height: 16,
      marginLeft: 2,
      marginRight: 2,
    };

    return (
      <div style={{ float: "right", textAlign: "right" }}>
        <label style={labelStyle}>Connected accounts</label>
        {accs.google ? (
          <img
            src={window._jsRoot + "static/images/g-logo.png"}
            style={imgStyle}
          />
        ) : (
          ""
        )}
        {accs.spotify ? (
          <img
            src={window._jsRoot + "static/images/spotify-logo.png"}
            style={imgStyle}
          />
        ) : (
          ""
        )}
        {accs.eth ? (
          <img
            src={window._jsRoot + "static/images/eth-logo.png"}
            style={imgStyle}
          />
        ) : (
          ""
        )}
        {!accs.spotify ? (
          <div style={{ marginTop: 10 }}>
            <button
              className="btn btn-default"
              onClick={this.handleSpotifyConnectClick}
            >
              Connect with Spotify
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

  _handleSpotifyConnectClick(e) {
    this.props.showLoginModal();
  }
}

UserDetails.propTypes = {
  user: PropTypes.object,
  count: PropTypes.number,
};

module.exports = UserDetails;
