
var React = require('react');
var ModalMixin = require('./Modal');
var cookie = require('cookie');
var UserActions = require('../actions/UserActions');

var Api = require('../utils/Api.js');

var secrets = require('../constants/secrets');

module.exports = React.createClass({
  mixins: [ModalMixin],
  modalId: 'usernameModal',

  getInitialState: function(){
    return {
      username: '',
      error: ''
    }
  },

  shown: function(){
    this.refs.username.focus();
  },

  getTitle: function(){
    var firstName = this.getFirstName();
    return `Welcome ${firstName}!`;
  },

  getBody: function(){
    return (
      <div className="username-form-container">
        <p className="username-form-title">Well done! You just need to pick a username to user in Great DJ! and you'll be good to go.</p>
        <div className="username-thumbnail-container pull-right">
          <img src={ this.getThumbnail() } alt="That's you!" title="That's you!" />
        </div>
        <form id="username-form" onSubmit={this.saveUsername}>
          <label>Username:</label>
          <input type="text" className={"form-text-input " + (this.state.error ? "input-error" : "")} name="username" ref="username" defaultValue={ this.getDefaultUsername() }/>
          {
            this.state.error ?
              <p className="error-p">{ this.state.error }</p> : ''
          }
          { /*
          <div>
            <label>Set a password for use in Great DJ:</label>
            <input type="password" name="pw" ref="pw" />
          </div>
          */ }
        </form>
        <label>Email:</label>
        <input type="text" disabled value={ this.props.user.email } className="form-text-input" />
      </div>
    );
  },

  getFooter: function(){
    return (
      <button className="btn btn-primary pull-right" style={{marginLeft: 6}} type="submit" form="username-form">Sounds good!</button>
    );
  },

  getFirstName: function(){
    if(this.props.user.display_name){
      return this.props.user.display_name.split(' ')[0];
    } else {
      return this.props.user.username;
    }
  },

  getThumbnail: function(){
    if(!this.props.user.images) return;
    if(this.props.user.images[0]){
      return this.props.user.images[0].url;
    } else {
      return '/static/images/user_icon.png';
    }
  },

  getDefaultUsername: function(){
    // has to come from the server as it has to be available...
    return this.props.user.username;
  },

  saveUsername: function(e){
    e.preventDefault();
    var username = this.refs.username.value;
    if(username && encodeURIComponent(username) === username){
      Api.setUsername(username, (err, response) => {
        if(!err){
          UserActions.updateUsername(response.body.username);
          this.hide();
        } else if(err){
          this.setState({error: err.response.body.error});
        }
      });
    } else {
      this.setState({error: 'Sorry, invalid username. Try one with letters and numbers and no special characters.'});
    }
  }
})
