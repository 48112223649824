var AppDispatcher = require('../dispatcher/AppDispatcher');
var Constants = require('../constants/AppConstants');
var Api = require('../utils/Api.js');

module.exports = {
  loadUserDetails(username, cb){
    this.clearUserDetails();
    Api.loadUserDetails(username, (err, user) => {
      if(err){
        if(err.status === 404){
          AppDispatcher.handleViewAction({
            actionType: Constants.USER_DETAILS_USER_NOT_FOUND,
            user
          });
        }
      } else if(user){
        AppDispatcher.handleViewAction({
          actionType: Constants.USER_DETAILS_LOADED,
          user
        });
      }

      if(cb) cb(user);
    })
  },
  clearUserDetails(fn){
    AppDispatcher.handleViewAction({
      actionType: Constants.USER_DETAILS_CLEAR,
    });
    fn && fn();
  }
}
