var React = require('react');
var dom = require('../utils/dom');

var PlaylistItem = React.createClass({

  propTypes: {
    position: React.PropTypes.number,
    classNames: React.PropTypes.string,
    video: React.PropTypes.object,
    handleDeleteEntry: React.PropTypes.func,
    handlePlayNow: React.PropTypes.func
  },

  handlePlayNow: function(e){
    console.log('handlePlayNow', e.target, e.target.tagName)
    e.preventDefault();

    if(dom.returnSelfOrParent(e.target, '[data-hook=delete]')) return;

    this.props.handlePlayNow(this.props.position, this.props.video);
    return false;
  },

  handleDeleteEntry: function(){
    this.props.handleDeleteEntry(this.props.position, this.props.video);
    return false;
  },

  render: function() {
    return (
      <li draggable="true" ref="el" data-pos={this.props.position} className={this.props.liClassNames}>
        <a draggable="false" className={this.props.classNames} href="#" onClick={this.handlePlayNow} data-hook="playlist-item">
          <span className="bars"><i className="fa fa-bars"></i></span>
          <span className="title"><span className="pos">{this.props.position + 1}</span>{this.props.video.snippet.title}</span>
          <span className="delete" onClick={this.handleDeleteEntry} data-hook="delete"><i className="far fa-trash-alt"></i></span>
          <span className="drop-zone"></span>
        </a>
      </li>
    );
  }
});

module.exports = PlaylistItem;
