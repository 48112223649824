var React = require("react");

var AutoComplete = require("./AutoComplete");
var Tooltip = require("./Tooltip");
var OverlayTrigger = require("./OverlayTrigger");
var PlaylistActions = require("../actions/PlaylistActions");
var SearchActions = require("../actions/SearchActions");
var UsernameModal = require("./UsernameModal");
var Api = require("../utils/Api.js");
var UserActions = require("../actions/UserActions.js");
var Dropdown = require("./Dropdown");

var request = require("superagent");
var page = require("page");

// URL for the youtube autocomplete (EN/GB locale)
var youtubeCompleteUrl =
  "https://clients1.google.com/complete/search?client=youtube&hl=en&gl=gb&gs_rn=23&gs_ri=youtube&ds=yt&cp=2&gs_id=1a&callback=showAutocompleteOptions&q=";
var autoCompleteTimeout;

var TopBar = React.createClass({
  getInitialState: function () {
    let firstVisit = false;
    if (this.props.playlistId && window.localStorage && !window.app.isEmbed) {
      firstVisit = localStorage.getItem("firstVisit") !== "false";
    }

    if (window.localStorage && !window.app.isEmbed) {
      localStorage.setItem("firstVisit", "false");

      if (firstVisit && !(window.DATA.playlists && window.DATA.playlists[0])) {
        window.ga &&
          window.ga("send", {
            hitType: "event",
            eventCategory: "Interactions",
            eventAction: "show:createNew",
          });
      } else if (window.DATA.playlists && window.DATA.playlists[0]) {
        window.ga &&
          window.ga("send", {
            hitType: "event",
            eventCategory: "Interactions",
            eventAction: "show:localParty",
          });
      }
    }

    return {
      complete: [],
      selected: -1,
      hideMessage: false,
      currentQuery: this.props.currentQuery || "",
      expandedSearch: false,
      showUsernameModal: false,
      firstVisit,
      showMessage:
        (window.DATA.playlists && window.DATA.playlists[0]) || firstVisit,
    };
  },

  componentDidMount: function () {
    var that = this;
    window.showAutocompleteOptions = function (res) {
      that.setState({ complete: res[1] || [], selected: -1 });
    };

    document.querySelector("input.q").focus();
  },

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.currentQuery &&
      nextProps.currentQuery !== this.props.currentQuery
    ) {
      this.setState({ currentQuery: nextProps.currentQuery });
    }

    if (nextProps.user && nextProps.user.firstLogin) {
      this.showUsernameModal();
      UserActions.unsetFirstLogin();
    }
  },

  componentDidUpdate() {
    if (this.state.showSearchTooltip) {
      document.querySelector("input.q").focus();
      this.setState({ showSearchTooltip: false });
    }
  },

  setQuery: function (q) {
    if (q && q.trim()) {
      if (autoCompleteTimeout) {
        clearTimeout(autoCompleteTimeout);
        autoCompleteTimeout = null;
      }

      this.props.setQuery(q);

      window.ga &&
        window.ga("send", {
          hitType: "event",
          eventCategory: "Search",
          eventAction: "simple",
          eventLabel: q,
        });
    } else {
      this.clearSearch();
    }

    // reset autocomplete
    this.setState({ complete: [], selected: -1 });
  },

  handleFormSubmit(e) {
    e.preventDefault();

    if (!this.state.expandedSearch) {
      this.setQuery(this.state.currentQuery);
    } else {
      var q = this.refs.textarea.value;
      if (q && q.trim()) {
        SearchActions.expandedSearch(q);
        // reset autocomplete
        this.setState({ complete: [], selected: -1 });
        window.ga &&
          window.ga("send", {
            hitType: "event",
            eventCategory: "Search",
            eventAction: "multiple",
            eventLabel: q,
          });
      }
    }
  },

  handleInputChange: function (e) {
    var q = e.target.value,
      that = this;

    this.setState({ currentQuery: q });

    if (autoCompleteTimeout) {
      clearTimeout(autoCompleteTimeout);
    }

    autoCompleteTimeout = setTimeout(function () {
      // remove old script tags
      document
        .querySelectorAll("script.autocompleteJSONP")
        .forEach(function (s) {
          s.parentNode.removeChild(s);
        });

      // if there's a query get a new one, otherwise clear
      if (q) {
        var script = document.createElement("script");
        script.setAttribute("class", "autocompleteJSONP");
        script.setAttribute("src", youtubeCompleteUrl + q);
        document.head.appendChild(script);
      } else {
        that.setState({ complete: [], selected: -1 });
      }
    }, 150);
  },

  // Autocomplete event handler
  handleInputKeyDown: function (e) {
    if (e.keyCode === 38) {
      //up
      if (this.state.selected >= 0) {
        this.setState({ selected: this.state.selected - 1 });
      }
      // always returning false on up - no 'go to beggining' behaviour
      return false;
    } else if (e.keyCode === 40) {
      //down
      if (this.state.selected < this.state.complete.length - 1) {
        this.setState({ selected: this.state.selected + 1 });
        return false;
      }
    } else if (e.keyCode === 13 && this.state.selected >= 0) {
      //enter
      e.preventDefault();
      this.setQuery(this.state.complete[this.state.selected][0]);
      return false;
    } else if (e.keyCode === 27) {
      //esc
      this.setState({ complete: [], selected: -1 });
      return false;
    } else if (e.keyCode === 9) {
      //tab
      if (
        !this.state.complete.length ||
        this.state.complete[this.state.selected]
      )
        return true;
      e.preventDefault();
      this.setState({
        currentQuery: this.state.complete[this.state.selected][0],
      });
      return false;
    }
  },

  handleMouseEnterOption: function (e) {
    this.setState({ selected: +e.target.getAttribute("data-order") });
  },

  handleMouseLeaveOption: function (e) {
    this.setState({ selected: -1 });
  },

  handleLuckyPerson: function (e) {
    var q = this.refs.textarea.value;
    if (!q || !q.trim()) return;

    PlaylistActions.reset();
    SearchActions.expandedSearch(q, true);

    window.ga &&
      window.ga("send", {
        hitType: "event",
        eventCategory: "Search",
        eventAction: "multiple:lucky",
        eventLabel: q,
      });

    // reset autocomplete
    this.setState({ complete: [], selected: -1 });
  },

  toggleExpandedSearch: function () {
    this.setState({ expandedSearch: !this.state.expandedSearch });
  },

  handleSavePlaylist: function () {
    this.props.handleSavePlaylist(this.playlistSaved);
  },

  playlistSaved: function (id, newPlaylist) {
    var that = this;
    var saveBtn = this.refs.saveTooltip.refs.element;

    if (newPlaylist) {
      window.scrollTo(0, 0);
    } else {
      this.refs.saveTooltip.show();
    }

    saveBtn.classList.add("saved");

    setTimeout(function () {
      if (id) that.refs.saveTooltip.hide();
      saveBtn.classList.remove("saved");
    }, 4500);
  },

  clearSearch: function () {
    this.props.setQuery();
    SearchActions.resetResults();
    // reset autocomplete
    this.setState({ complete: [], selected: -1, currentQuery: "" });
  },

  handleLogoClick: function (e) {
    e.preventDefault();

    if (!this.state.currentQuery) {
      // means introcomponent already mounted
      // so will have to call this here
      SearchActions.refreshRecentTerms();
    }

    this.clearSearch();
    page("/");
    PlaylistActions.reset();

    PlaylistActions.getPopularPlaylists();
  },

  handleHdOnlyChange: function (e) {
    var hd = e.target.checked;
    this.props.setHdOnly(hd);
  },

  handleJoinParty: function (e) {
    e.preventDefault();

    var plId = window.DATA.playlists[0];

    PlaylistActions.unsync();
    this.props.setPosition(-1);
    history.pushState(null, null, "/" + plId);
    PlaylistActions.sync(plId);

    this.handleHideMessage();
  },

  handleHideMessage: function (e) {
    if (e) e.preventDefault();
    this.setState({ hideMessage: true, showMessage: false });
    this.refs.desktopJoinPl.classList.remove("in");
  },

  showUsernameModal: function () {
    this.setState({ showUsernameModal: true });
  },

  usernameModalHidden: function () {
    this.setState({ showUsernameModal: false });
  },

  showProfile: function () {
    page(`/playlists/${this.props.user.username}`);
  },

  handleCreateNewPlaylist(e) {
    e.preventDefault();
    this.handleHideMessage(e);
    this.setState({ showSearchTooltip: true });
    page(`/`);
    window.ga &&
      window.ga("send", {
        hitType: "event",
        eventCategory: "Interactions",
        eventAction: "click:createNew",
      });
  },

  render: function () {
    var that = this;
    var labelStyle = this.props.sync
      ? { display: "inline-block" }
      : { display: "none" };
    var clientStr =
      this.props.partyClients && this.props.partyClients > 1
        ? "clients"
        : "client";

    if (this.state.showMessage && !this.props.sync && !this.state.hideMessage) {
      setTimeout(function () {
        if (that.state.hideMessage || that.props.sync) return;
        that.refs.desktopJoinPl.classList.add("in");
      }, 3000);
    }

    var formClassName = "topbar-form";
    if (this.props.sync) formClassName += " sync";
    if (this.state.expandedSearch) formClassName += " expanded-search-form";

    var searchPlaceholder =
      "Start by searching for artists, songs or paste the address of a page with songs/videos.";

    return (
      <div className="top-bar">
        <span className="desktop-join-pl desktop" ref="desktopJoinPl">
          {this.state.firstVisit ? (
            <span>
              Welcome to GreatDJ, the best place to create and share your best
              music playlists! Have fun listening to this one or{" "}
              <a href="/" onClick={this.handleCreateNewPlaylist}>
                click here to create a new one now!
              </a>
            </span>
          ) : (
            <span>
              There&#39;s a party going on on your network at great.dj/
              {window.DATA.playlists && window.DATA.playlists[0]}!&nbsp;
              <a href="#" onClick={this.handleJoinParty}>
                Connect?
              </a>
              <a
                href="#"
                onClick={this.handleHideMessage}
                className="hide-message"
              >
                x
              </a>
            </span>
          )}
        </span>

        <form onSubmit={this.handleFormSubmit} className={formClassName}>
          <span className="logo-label-container desktop">
            <span className="logo" data-tooltip="Start over">
              <a href="/" onClick={this.handleLogoClick}>
                GREAT DJ<span className="it">!</span>
              </a>
            </span>
            {this.props.sync ? (
              <span className="label" style={labelStyle}>
                {this.props.partyClients} {clientStr}
              </span>
            ) : (
              <span></span>
            )}
          </span>
          <span className="queryInputContainer">
            <div style={{ display: "flex" }}>
              {!this.state.expandedSearch || this.props.view !== "search" ? (
                <input
                  type="text"
                  className="q"
                  onChange={this.handleInputChange}
                  onKeyDown={this.handleInputKeyDown}
                  placeholder={searchPlaceholder}
                  value={this.state.currentQuery}
                />
              ) : (
                <div className="textarea-container">
                  <textarea
                    className="q"
                    ref="textarea"
                    placeholder="Search for multiple songs and artists, one per line."
                    disabled={this.props.searching}
                  ></textarea>
                </div>
              )}
              <i
                className={
                  "clear-search fa fa-times " +
                  (this.props.currentQuery && !this.state.expandedSearch
                    ? "in"
                    : "")
                }
                onClick={this.clearSearch}
              />

              {!this.props.searching ? (
                <input type="submit" value="Search" />
              ) : (
                <input type="submit" value="Searching..." disabled="disabled" />
              )}
              {this.state.expandedSearch && this.props.view === "search" ? (
                !this.props.waitingForPlaylistResult ? (
                  <button
                    type="button"
                    className="expanded-lucky"
                    onClick={this.handleLuckyPerson}
                  >
                    I'm feeling lucky
                  </button>
                ) : (
                  <button
                    type="button"
                    className="expanded-lucky"
                    disabled="disabled"
                  >
                    Hold tight...
                  </button>
                )
              ) : (
                ""
              )}
              {this.props.view === "search" ? (
                <button
                  type="button"
                  className="expanded-search"
                  onClick={this.toggleExpandedSearch}
                >
                  {!this.state.expandedSearch
                    ? "Search for multiple songs"
                    : "Normal search"}
                </button>
              ) : (
                ""
              )}
              {!this.state.expandedSearch ? (
                <AutoComplete
                  disable={!this.state.expandedSearch}
                  complete={this.state.complete}
                  selected={this.state.selected}
                  setQuery={this.setQuery}
                  handleMouseEnterOption={this.handleMouseEnterOption}
                  handleMouseLeaveOption={this.handleMouseLeaveOption}
                />
              ) : (
                ""
              )}

              <input
                type="checkbox"
                className="hide"
                value="HD Only"
                id="hd-checkbox"
                onChange={this.handleHdOnlyChange}
                disabled={this.props.views === "playlists"}
              />
              <label htmlFor="hd-checkbox" className="hide">
                {" "}
                HD Only{" "}
              </label>
            </div>
          </span>

          <span className="logo-label-container mobile tablet">
            <span className="logo">
              <a href="/" onClick={this.handleLogoClick}>
                GREAT DJ<span className="it">!</span>
              </a>
            </span>
          </span>

          <div className="toolbox">
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip>
                  With party mode on, multiple people can control this playlist
                  at the same time.
                </Tooltip>
              }
            >
              <button
                className={
                  (this.props.sync ? "sync active" : "sync") + " action-button"
                }
                type="button"
                onClick={this.props.toggleSync}
              >
                Party Mode
              </button>
            </OverlayTrigger>

            <OverlayTrigger
              ref="saveTooltip"
              placement="bottom"
              trigger="manual"
              overlay={<Tooltip moreClasses="save">Saved!</Tooltip>}
            >
              <button
                className="save action-button"
                type="button"
                onClick={this.handleSavePlaylist}
              >
                Save
              </button>
            </OverlayTrigger>

            {this.props.user ? (
              this.renderUserDropdown()
            ) : (
              <button
                className="user action-button"
                type="button"
                onClick={this.props.showLoginModal}
              >
                <span>Log in</span>
              </button>
            )}
          </div>
        </form>

        <UsernameModal
          showing={this.state.showUsernameModal}
          hidden={this.usernameModalHidden}
          user={this.props.user || {}}
        />
      </div>
    );
  },

  renderUserDropdown() {
    var user = this.props.user;
    /*
        options={[
          {label: 'My playlists', href: `/playlists/${this.props.user.username}`},
          {label: 'Import from Spotify', href: '#'},
          {label: 'Settings ?? ', href: '#'}
        ]}
        */
    return (
      <Dropdown
        options={[
          { label: "Your playlists", ref: "playlists" },
          { label: "Logout", ref: "logout" },
        ]}
        style={{ display: "inline-block" }}
        handleButtonClick={this.showProfile}
        handleOptionClick={this.userDropdownSelected}
      >
        <div className="user-info" type="button">
          <div className="user-info-pic-container">
            <img
              src={
                user.images && user.images.length
                  ? user.images[0].url
                  : "/static/images/user_icon.png"
              }
            />
          </div>
          <div className="user-info-name">
            {user.display_name || user.username}
          </div>
        </div>
      </Dropdown>
    );
  },

  userDropdownSelected(e, i) {
    if (i === "logout") {
      this.props.logout();
    } else if (i === "playlists") {
      this.showProfile();
    }
  },
});

module.exports = TopBar;
