/* globals process */
var React = require('react');
var sdk = require('require-sdk')('https://www.youtube.com/iframe_api', 'YT');
var loadTrigger = sdk.trigger();

// YT API requires global ready event handler
if(process.env.BROWSER){
  window.onYouTubeIframeAPIReady = function () {
    loadTrigger();
    delete window.onYouTubeIframeAPIReady;
  };
}


var Player = React.createClass({
  getInitialState: function(){
    return {};
  },

  componentWillUnmount(){
    window.removeEventListener('keydown', this.onWindowKeyDown);
  },

  componentWillMount: function(){
    window.addEventListener('keydown', this.onWindowKeyDown);
  },

  onWindowKeyDown(e){
    if(['INPUT', 'TEXTAREA'].indexOf(e.target.tagName) === -1  && e.key === " "){
      e.preventDefault();

      this.getPlaying(playing => {
        if(playing) this.props.stopped();
        this.togglePlaying();
      })
    }
  },

  componentDidMount() {
    var that = this;

    this._initYoutubePlayer();
    this._initSoundcloudPlayer();

    window.addEventListener('restartVideo', function(){
      that.restartCurrentVideo();
    });

    this.activatePlayer('youtube');
  },

  componentWillUpdate: function(nextProps, nextState) {
    if (nextProps.video && (!this.props.video || this.props.video.id !== nextProps.video.id)) {
      this._loadNewVideo(nextProps.video);
    } else if (nextProps.video && this.props.position !== nextProps.position && this.state.youtube.getPlayerState && !this.state.youtube.getPlayerState()){
      this._loadNewVideo(nextProps.video);
    } else if(!nextProps.video && this.props.video){
      this._stopCurrentVideo();
      this.refs.curtain.style.display = 'block';
      //this.restartCurrentVideo();
      this.activatePlayer('youtube');
    }
  },

  componentDidUpdate(oldprops){
    this.getPlaying(playing => {
      if(this.props.paused && playing && !oldprops.paused){
        this._pauseCurrentVideo();
      }
      if(!this.props.paused && !playing && oldprops.paused){
        this.togglePlaying();
      }
    });
  },

  _loadNewVideo: function(video) {
    this._stopCurrentVideo();
    this.activatePlayer(video.source);

    switch(video.source){
      case 'soundcloud':
        this._loadNewSCTrack(video);
        break;
      default: //case 'youtube'
        this._loadNewYTVideo(video);
        break;

    }
  },

  _loadNewYTVideo: function(video){
    //var startAt = this.props.dts && this.props.dts > 2000 ?  this.props.dts/1000 : 0;

    var startAt = 0;

    if(this.state.youtube && this.state.youtube.loadVideoById){
      this.props.autoplay ?
        this.state.youtube.loadVideoById(video.id, startAt, "hd720") :
        this.state.youtube.cueVideoById(video.id, startAt, "hd720");

      this.state.youtube.setPlaybackQuality("hd720");
      this.refs.curtain.style.display = 'none';
    } else {
      console.warn('* Player: Can\'t play yet!');
    }
  },

  _loadNewSCTrack: function(video){
    if(this.state.soundcloud && this.state.soundcloud.load){
      this.state.soundcloud.load(video.url, {auto_play: true});
    } else {
      console.warn('* Player: Can\'t play yet!');
    }
  },

  restartCurrentVideo: function(){
    if(!this.state[this.activePlayer]) return;
    this.state[this.activePlayer].seekTo(0);
  },

  _pauseCurrentVideo: function(){
    if(!this.props.video) return;

    switch(this.props.video.source){
      case 'youtube':
        // not sure why but this has been happening...
        if(!this.state.youtube) return;
        if(this.state.youtube.pauseVideo){
          this.state.youtube.pauseVideo();
        }
        break;

      case 'soundcloud':
        if(!this.state.soundcloud) return;
        this.state.soundcloud.pause();
        break;
    }
   },

  _stopCurrentVideo: function(){
    if(!this.props.video) return;

    switch(this.props.video.source){
      case 'youtube':
        // not sure why but this has been happening...
        if(!this.state.youtube) return;
        if(this.state.youtube.stopVideo){
          this.state.youtube.stopVideo();
        }
        if(this.state.youtube.loadVideoById){
          this.state.youtube.loadVideoById('',0);
        }
        break;

      case 'soundcloud':
        if(!this.state.soundcloud) return;
        this.state.soundcloud.pause();
        break;
    }
   },

  togglePlaying: function(){
    if(!this.activePlayer || !this.props.video) return false;
    switch(this.props.video.source){
      case 'youtube':
        if(!this.state.youtube || !this.state.youtube.getPlayerState) return;
        if(this.state.youtube.getPlayerState() === 1){
          this.state.youtube.pauseVideo();
        } else {
          this.state.youtube.playVideo();
        }
      case 'soundcloud':
        if(this.state.soundcloud) this.state.soundcloud.toggle();
      default:
        return false;
    }
  },

  getPlaying(callback){
    if(!this.activePlayer || !this.props.video) return false;
    switch(this.props.video.source){
      case 'youtube':
        if(!this.state.youtube || !this.state.youtube.getPlayerState) return false;
        callback(this.state.youtube.getPlayerState() === 1);
        return;
      case 'soundcloud':
        if(!this.state.soundcloud) return false;
        return this.state.soundcloud.isPaused(callback);
      default:
        return false;
    }
  },

  activatePlayer: function(source){
    switch(source){
      case 'youtube':
        this.refs.ytPlayer.style.display = 'block';
        this.refs.scPlayer.style.display = 'none';
        this.state.soundcloud && this.state.soundcloud.pause();
        break;

      case 'soundcloud':
        this.refs.scPlayer.style.display = 'block';
        this.refs.ytPlayer.style.display = 'none';
        this.state.youtube && this.state.youtube.pauseVideo();
        break;
    }

    this.activePlayer = source;
  },

  _handlePlayerStateChange: function(event) {
    switch(event.data) {
      case 0:
        this.props.ended();
        break;

      case 1:
        this.props.playing();
        break;

      case 2:
        this.props.stopped();
        break;

      default:
        return;
    }
  },

  _initYoutubePlayer: function(){
    var that = this;

    // called once API has loaded.
    sdk(function(err, youtube) {
      var player = new youtube.Player("youtube-player", {
        //videoId: that.props.videoId,
        playerVars: {
          playsinline: 1
        },
        events: {
          'onReady': that.props.playerReady,
          'onStateChange': that._handlePlayerStateChange
        }
      });

      that.setState({youtube: player});
    });
  },

  _initSoundcloudPlayer: function(){
    var scIframe = this.refs.scPlayerFrame;
    scIframe.src = 'https://w.soundcloud.com/player/?url=';

    var scPlayer = SC.Widget(scIframe);
    scPlayer.bind(SC.Widget.Events.PLAY, this.props.playing);
    scPlayer.bind(SC.Widget.Events.FINISH, this.props.ended);

    this.setState({soundcloud: scPlayer});
  },

  getPlayingPosition: function(){
    if(!this.props.video) return 0;

     switch(this.props.video.source){
      case 'youtube':
        var dur = this.state.youtube.getDuration();
        return dur ? this.state.youtube.getCurrentTime() / dur : 0;
      break;

      case 'soundcloud':
        return 1; // @todo - not implemented yet
      break;
    }
  },

  render: function() {
    return (
      <div className="players">
        <div className="youtube-player-container" ref="ytPlayer">
          <div className="curtain" ref="curtain"></div>
          <div id="youtube-player"></div>
        </div>
        <div className="soundcloud-player" ref="scPlayer">
          <iframe ref="scPlayerFrame"></iframe>
        </div>
      </div>
    );
  }

});

module.exports = Player;
