const React = require('react');
const ChatActions = require('../actions/ChatActions');

class ChatComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      message: ''
    };

    this.handleChatFormSubmit = this.handleChatFormSubmit.bind(this);
  }

  handleChatFormSubmit(e){
    e.preventDefault();
    if(!this.state.message) return;
    ChatActions.sendMessage(this.state.message, this.props.playlistId);
    this.setState({message: ''});
  }

  formatTime(date){
    if(!date) return '';
    let hour = date.getHours();
    let min = date.getMinutes();
    return `${this.ensureTwo(hour)}:${this.ensureTwo(min)}`;
  }

  ensureTwo(number){
    if(number > 9) return new String(number);
    return "0"+number;
  }

  componentDidUpdate(oldprops){
    if(this.props.messages !== oldprops.messages && this.refs && this.refs.messages){
      this.refs.messages.scrollTop = this.refs.messages.scrollHeight;
    }
  }

  render(){
    return (
      <div className="chat-component">
        <div className="message-container" ref="messages">
          { this.props.messages.map(msg => 
            <p className={"message" + (msg.type ? ` message-${msg.type}` : '') + (msg.isOwn ? ` message-own` : '')}>
              <span className="message-date">{ this.formatTime(msg.date) }</span>
              { msg.user ? 
                  <span className="message-user">{msg.user}</span>
                  : ''
              }
              <span> {msg.text}</span>
            </p>
          )}
        </div>
        <div className="input-container">
          <form onSubmit={ this.handleChatFormSubmit }>
            <input type="text" placeholder="Message..." value={this.state.message} onChange={(e) => this.setState({message: e.target.value})}/>
            <button type="submit" className="btn btn-primary">Send</button>
          </form>
        </div>
      </div>
    );
  }
}

module.exports = ChatComponent;
