var dom = {
  // util for getting a parent element that matches the selector
  returnSelfOrParent: function(el, selector){
    if(!el || el.tagName === 'BODY') return undefined;

    var matches = el.parentNode.querySelectorAll(selector);

    for (var i = matches.length - 1; i >= 0; i--) {
      if(matches[i] === el) return el;
    }

    return dom.returnSelfOrParent(el.parentNode, selector);

  },
};

module.exports = dom;