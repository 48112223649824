/* global process, SC */

var request = require("superagent");
var AppDispatcher = require("../dispatcher/AppDispatcher");
var Constants = require("../constants/AppConstants");
var io = require("socket.io-client");
var _ = require("underscore");
const cookie = require("cookie");

var secrets = process.env.BROWSER ? require("../constants/secrets") : {};

// Youtube API Key
var API_KEY = secrets.youtubeKey;

const DEV_HOST = "great.localhost";

// this is wrong
const API_ROOT = process.env.DEV
  ? `http://${DEV_HOST}:8090`
  : process.env.API_HOST || "https://api.great.dj";

var expandSearchURL = process.env.DEV
  ? `//api.${DEV_HOST}:4001`
  : "https://great.dj/importer";
var expandSearchKey = secrets.importerKey;

var _recommendationCache = {};

function dispatch(key, response, params) {
  var payload = { actionType: key, response: response };
  if (params) {
    payload.queryParams = params;
  }
  AppDispatcher.handleServerAction(payload);
}

var Api = {
  savePlaylist: function (pl, plId, fn) {
    var key = Constants.PLAYLIST_SAVED;
    var params = { playlist: pl, id: plId };

    var newPlaylist = !plId;

    dispatch(key, Constants.request.PENDING, params);

    request
      .post(API_ROOT + "/p")
      .withCredentials()
      .send(params)
      .end(function (err, response) {
        if (!err) {
          dispatch(
            key,
            {
              playlistId: response.body.id,
              v: response.body.v,
              settings: _.extend({}, response.body.data.settings, {
                created: response.body.data.created,
              }),
              playlist: response.body.data,
            },
            params
          );

          if (fn) {
            fn(newPlaylist, { id: response.body.id, hash: response.body.hash });
          }

          if (newPlaylist && window.ga) {
            window.ga("send", {
              hitType: "event",
              eventCategory: "Playlists",
              eventAction: "create",
              eventLabel: response.body.id,
            });
          }
        } else {
          dispatch(key, Constants.request.ERROR, err);
        }
      });
  },

  updatePlaylistSettings: function (plId, hash, settings) {
    var key = Constants.PLAYLIST_CHANGE;
    var params = {
      id: plId,
      hash: hash,
      settings: settings,
    };

    dispatch(key, { changes: { settings: settings } }, params);

    request
      .post(API_ROOT + "/p_settings")
      .withCredentials()
      .send(params)
      .end(function (err, response) {
        if (err) {
          dispatch(key, Constants.request.ERROR, params);
        } else {
          dispatch(
            key,
            {
              changes: {
                settings: response.body.settings,
              },
            },
            params
          );
        }
      });
  },

  loadPlaylist: function (plId, version, preventRedirect, fn) {
    var key = Constants.PLAYLIST_LOADED;
    var params = { id: plId, version, preventRedirect };

    dispatch(key, Constants.request.PENDING, params);

    request
      .get(API_ROOT + "/p")
      .withCredentials()
      .query(params)
      .end(function (err, response) {
        if (!err && response.body.id) {
          dispatch(
            key,
            {
              playlist: response.body.playlist,
              playlistId: response.body.id,
              playlistSettings: _.extend({}, response.body.settings, {
                created: response.body.created,
              }),
              v: response.body.playlistVersion,
              username: response.body.username,
            },
            params
          );

          if (fn) {
            fn(response.body.id, response.body.playlist);
          }
        } else {
          dispatch(key, Constants.request.ERROR, params);
        }
      });
  },

  deletePlaylist: function (id, cb) {
    request
      .delete(API_ROOT + "/p")
      .withCredentials()
      .send({ id })
      .end((err, resp) => {
        cb(err, resp);
      });
  },

  searchForVideos: function (q, videoDef) {
    var key = Constants.SEARCH_SUCCESS;

    // Youtube
    request
      .get("https://www.googleapis.com/youtube/v3/search")
      .query({
        key: API_KEY,
        part: "snippet",
        q: q,
        type: "video",
        maxResults: 15,
        videoDefinition: videoDef,
        topicId: "/m/04rlf",
      })
      .end(function (err, response) {
        var items = response.body.items.map(function (video) {
          return {
            id: video.id.videoId,
            source: "youtube",
            snippet: {
              title: video.snippet.title,
              thumbnail: video.snippet.thumbnails.medium.url,
            },
            url: "http://www.youtube.com/watch?v=" + video.id.videoId,
          };
        });

        dispatch(key, { items: items, source: "youtube" });
      });

    // Soundcloud
    SC.get("/tracks", { q: q, limit: 15 }, function (tracks) {
      var items = tracks.map(function (track) {
        return {
          id: track.id,
          source: "soundcloud",
          snippet: {
            title: track.title,
            thumbnail: track.artwork_url,
          },
          url: track.uri,
        };
      });

      dispatch(key, { items: items, source: "soundcloud" });
    });

    request
      .post(API_ROOT + "/s")
      .withCredentials()
      .send({ term: q })
      .end();
  },

  searchForPlaylists: function (q) {
    var key = Constants.SEARCH_SUCCESS;

    // Youtube
    request
      .get("https://www.googleapis.com/youtube/v3/search")
      .query({
        key: API_KEY,
        part: "snippet",
        q: q,
        type: "playlist",
        maxResults: 6,
      })
      .end(function (err, response) {
        var items = response.body.items.map(function (pl) {
          return {
            id: pl.id.playlistId,
            source: "youtube-playlist",
            snippet: {
              title: pl.snippet.title,
              thumbnail:
                pl.snippet.thumbnails && pl.snippet.thumbnails.medium
                  ? pl.snippet.thumbnails.medium.url
                  : "",
            },
            //        playlistSize: res....
          };
        });

        dispatch(key, { items: items, source: "youtube-playlist" });
      });
  },

  getVideosFromYoutubePlaylist: function (plId, callback) {
    // Youtube
    request
      .get("https://www.googleapis.com/youtube/v3/playlistItems")
      .query({
        key: API_KEY,
        part: "snippet",
        playlistId: plId,
        maxResults: 20,
      })
      .end(function (err, response) {
        var items = response.body.items.map(function (video) {
          return {
            id: video.snippet.resourceId.videoId,
            source: "youtube",
            snippet: {
              title: video.snippet.title,
              thumbnail:
                video.snippet.thumbnails && video.snippet.thumbnails.medium
                  ? video.snippet.thumbnails.medium.url
                  : "",
            },
            url: "http://www.youtube.com/watch?v=" + video.id.videoId,
          };
        });

        callback(items);
      });
  },

  getVideosFromUrl: function (url) {
    var key = Constants.SEARCH_SUCCESS;

    request
      .post(API_ROOT + "/url_s")
      .withCredentials()
      .send({
        url: url,
      })
      .end(function (err, response) {
        dispatch(key, { items: response.body.items, source: "youtube" });
      });
  },

  getVideosFromSpotifyPlaylist: function (url) {
    var key = Constants.SEARCH_SUCCESS;

    request
      .post(API_ROOT + "/spotify_s")
      .withCredentials()
      .send({
        url: url,
      })
      .end(function (err, response) {
        dispatch(key, { items: response.body.items, source: "youtube" });
      });
  },

  getFacebookPageVideos: function (id) {
    var key = Constants.SEARCH_SUCCESS;

    request
      .post(API_ROOT + "/fb_s")
      .withCredentials()
      .send({
        id: id,
      })
      .end(function (err, response) {
        dispatch(key, { items: response.body.items, source: "youtube" });
      });
  },

  searchExpanded: function (terms, setPlaylist) {
    var key = setPlaylist ? Constants.SET_PLAYLIST : Constants.SEARCH_SUCCESS;
    var list = terms
      .split("\n")
      .filter((t) => !!t)
      .slice(0, 100);

    if (setPlaylist) dispatch(Constants.RESET);

    request
      .post(expandSearchURL)
      .set("Authorization", expandSearchKey)
      .send({ list })
      .end((err, response) => {
        let items = response.body || [];
        items = items.filter((r) => !!r);
        dispatch(key, { items: items, expanded: true });
        if (setPlaylist) {
          dispatch(Constants.EXPAND_PL_RESPONSE);
        }
      });
  },

  getRecentTerms: function () {
    var key = Constants.RECENT_TERMS;
    request
      .get(API_ROOT + "/s")
      .withCredentials()
      .end(function (err, response) {
        if (response.body) {
          dispatch(key, { terms: response.body.terms });
        }
      });
  },

  getPopularPlaylists: function () {
    var key = Constants.POPULAR_PLAYLISTS;
    request
      .get(API_ROOT + "/popular")
      .withCredentials()
      .end(function (err, response) {
        dispatch(key, { playlists: response.body.playlists });
      });
  },

  loadAllPlaylists: function (perPage, page, query, user) {
    var key = Constants.PLAYLISTS_LOADED;
    request
      .get(API_ROOT + "/getPlaylists")
      .withCredentials()
      .query({ perPage: perPage, page: page, query: query, user })
      .end(function (err, response) {
        dispatch(key, { data: response.body.data, query: query });
      });
  },

  checkForId: function (id, fn) {
    request
      .get(API_ROOT + "/is_id_available")
      .withCredentials()
      .query({ id })
      .end(function (err, response) {
        fn(response.body.available);
      });
  },

  updatePlaylistId: function (id, hash, newId) {
    var key = Constants.PLAYLIST_CHANGE;

    request
      .post(API_ROOT + "/change_id")
      .withCredentials()
      .send({ id: id, newId: newId, hash: hash })
      .end(function (err) {
        if (!err) dispatch(key, { changes: { id: newId } });
      });
  },

  refreshPlaylistImage: function (id, hash) {
    var key = Constants.PLAYLIST_CHANGE;

    request
      .post(API_ROOT + "/change_image")
      .withCredentials()
      .send({ id: id, hash: hash })
      .end(function (err, response) {
        if (!err)
          dispatch(key, { changes: { settings: response.body.settings } });
      });
  },

  tagActionForPlaylist: function (id, tag, uuid, action) {
    var key = Constants.PLAYLIST_CHANGE;

    request
      .post(API_ROOT + "/tag")
      .withCredentials()
      .send({ id, tag, uuid, action })
      .end(function (err, response) {
        if (!err)
          dispatch(key, {
            changes: { settings: { tags: response.body.tags } },
          });
      });
  },

  getRelatedVideos: function (id, videoDef, fn) {
    if (_recommendationCache[id]) {
      fn(_recommendationCache[id]);
    } else {
      request
        .get("https://www.googleapis.com/youtube/v3/search")
        .query({
          key: API_KEY,
          part: "snippet",
          relatedToVideoId: id,
          type: "video",
          maxResults: 5,
          videoDefinition: videoDef,
        })
        .end(function (err, response) {
          var items = response.body.items.map(function (video) {
            return {
              id: video.id.videoId,
              source: "youtube",
              snippet: {
                title: video.snippet.title,
                thumbnail: video.snippet.thumbnails.medium.url,
              },
              url: "http://www.youtube.com/watch?v=" + video.id.videoId,
            };
          });

          _recommendationCache[id] = items;
          fn(items);
        });
    }
  },

  getPlaylistsSummary(ids) {
    var key = Constants.DESCRIBE_MY_PLAYLISTS;
    request
      .get(API_ROOT + "/p_summary")
      .withCredentials()
      .query({ ids })
      .end(function (err, r) {
        if (!err) dispatch(key, { data: r.body.data });
      });
  },

  getOwnPlaylists() {
    var key = Constants.DESCRIBE_MY_PLAYLISTS;
    request
      .get(API_ROOT + "/my_playlists")
      .withCredentials()
      .end(function (err, r) {
        if (!err) dispatch(key, { data: r.body });
      });
  },

  unsetUserFirstLogin(cb) {
    request
      .post(API_ROOT + "/unset_first_login")
      .withCredentials()
      .end(() => {
        if (cb) cb();
      });
  },

  isUsernameAvailable(username, cb) {
    request
      .get(API_ROOT + "/username")
      .query({ username })
      .withCredentials()
      .end(cb);
  },

  setUsername(username, cb) {
    request
      .post(API_ROOT + "/username")
      .withCredentials()
      .send({ username })
      .end((err, res) => {
        if (cb) cb(err, res);
      });
  },

  setUserToMyPlaylists(playlists, cb) {
    request
      .post(API_ROOT + "/set_user")
      .withCredentials()
      .send({ playlists })
      .end((err, res) => {
        if (cb) cb(res.body);
      });
  },

  loadUser(id, cb) {
    request
      .get(API_ROOT + "/user")
      .query({ id })
      .withCredentials()
      .end((err, res) => {
        if (cb) cb(res.body ? res.body.user : undefined);
      });
  },

  loadUserDetails(username, cb) {
    request
      .get(API_ROOT + "/user_details")
      .query({ username })
      .withCredentials()
      .end((err, res) => {
        if (err && err.status === 404) {
          cb(err, null);
          return;
        }
        cb(null, res.body.user);
      });
  },

  logout() {
    request.post(API_ROOT + "/logout").end();
  },

  authGoogleToken(token, cb) {
    request
      .post(API_ROOT + "/google_verify_token")
      .withCredentials()
      .send({ token })
      .end((err, res) => {
        cb(err, res);
      });
  },

  upsertUser(user, cb) {
    request
      .post(API_ROOT + "/upsert_user")
      .withCredentials()
      .send({ user })
      .end((err, res) => {
        cb(err, res);
      });
  },
};

Api.io = {
  socket: undefined,
  playlistVersion: undefined,

  register: function (id, playlist, position) {
    var that = this;

    var socketIoUrl =
      window.location && window.location.href.indexOf("great.localhost") >= 0
        ? "http://great.localhost:8090"
        : window._socketUrl;

    console.log("socket url:", socketIoUrl, window.STAGING);

    if (!this.socket) {
      console.log("connecting", socketIoUrl);
      this.socket = io(socketIoUrl, {});

      this.socket.on("playlistChange", function (data) {
        console.log("* Received playlist changed", data);

        // timestamp of the playlist we received
        // we will include it in future changedPlaylist requessts so the server
        // knows if the client is based on the latest available version
        that.playlistVersion = data.ts;

        dispatch(
          Constants.PLAYLIST_LOADED,
          {
            playlist: data.playlist,
            playlistId: data.id,
            position: data.position,
            ts: data.ts,
            user: data.user,
            positionIsTheSame: data.positionIsTheSame,
          },
          { id: data.id }
        );
      });

      this.socket.on("disconnect", function (info) {
        // server disconnected? turning party mode off
        console.log("socket disconnected", info);
        dispatch(Constants.SYNC_OFF);
        that.shouldReconnect = true;
      });

      this.socket.on("connect", () => {
        // hande reconnections on mobile due to innactivity / putting the browser in background...
        console.log("socket connect event", that.shouldReconnect);
        if (that.shouldReconnect) {
          var cookies = cookie.parse(document.cookie);
          this.socket.emit("register", {
            id,
            playlist,
            position,
            session: cookies.greatdjSession,
          });
          dispatch(Constants.SYNC_ON);
        }
      });

      this.socket.on("presence", function (data) {
        dispatch(Constants.PARTY_PRESENCE, {
          clients: data.clients,
          action: data.action,
          user: data.user,
        });
      });

      this.socket.on("chatMessage", (data) => {
        data.isOwn = data.clientId === this.socket.__clientId;
        dispatch(Constants.CHAT_MESSAGE, data);
      });

      this.socket.on("registered", (data) => {
        this.socket.__clientId = data.clientId;
      });

      this.socket.on("pause", (data) => {
        dispatch(Constants.PAUSE, data);
      });

      this.socket.on("play", (data) => {
        dispatch(Constants.PLAY, data);
      });
    }

    var cookies = cookie.parse(document.cookie);

    this.socket.emit("register", {
      id,
      playlist,
      position,
      session: cookies.greatdjSession,
    });
  },

  changedPlaylist: function (id, pl, pos) {
    if (this.socket) {
      this.socket.emit("changedPlaylist", {
        id: id,
        playlist: pl,
        position: pos,
        ts: this.playlistVersion,
      });
    } else {
      console.warn("Tried to send a changedPlaylist but socket not available");
    }
  },

  unregister: function () {
    if (this.socket) {
      this.socket.off("playlistChange");
      this.socket.emit("unregister");
      this.socket = null;
    }
  },

  sendChatMessage: function (text) {
    if (!this.socket) {
      dispatch(Constants.SOCKET_ERROR);
      return;
    }

    this.socket.emit("sendMessage", { text });
  },

  sendPauseEvent: function () {
    if (this.socket) {
      this.socket.emit("pauseVideo");
    }
  },

  sendPlayEvent: function () {
    if (this.socket) {
      this.socket.emit("playVideo");
    }
  },
};

module.exports = Api;
