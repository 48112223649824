const React = require("react");
const UserDetailsActions = require("../actions/UserDetailsActions.js");
const PlaylistsActions = require("../actions/PlaylistsActions");
const PlaylistsList = require("./PlaylistsList");
const _ = require("underscore");
const page = require("page");
const UserDetails = require("./UserDetails");

class Playlists extends React.Component {
  constructor(props) {
    super(props);
    this.buildUrl = this._buildUrl.bind(this);
    this.handleInputChange = this._handleInputChange.bind(this);
    this.handleNextClick = this._handleNextClick.bind(this);
    this.handlePreviousClick = this._handlePreviousClick.bind(this);
    this.hasNext = this._hasNext.bind(this);

    this.debouncedLoadPlaylists = _.debounce(
      this.loadPlaylists.bind(this),
      800
    );
  }

  componentDidMount() {
    PlaylistsActions.clear();
    UserDetailsActions.clearUserDetails();
    if (this.props.user) {
      UserDetailsActions.loadUserDetails(this.props.user);
    }
    this.loadPlaylists();
  }

  componentDidUpdate(prevProps) {
    if (this.props.page !== prevProps.page) {
      this.loadPlaylists();
    } else if (this.props.user !== prevProps.user) {
      PlaylistsActions.clear();
      if (!this.props.user) {
        UserDetailsActions.clearUserDetails(() => this.loadPlaylists());
      } else {
        UserDetailsActions.loadUserDetails(this.props.user, () =>
          this.loadPlaylists()
        );
      }
    } else if (this.props.playlistSearch !== prevProps.playlistSearch) {
      this.debouncedLoadPlaylists();
    }
  }

  loadPlaylists() {
    PlaylistsActions.load(
      this.props.perPage,
      this.props.page,
      this.props.playlistSearch,
      this.props.user ? this.props.user : undefined
    );
  }

  render() {
    let userInfo = this.props.userDetails ? (
      <UserDetails
        user={this.props.userDetails}
        count={this.props.count}
        isMe={
          this.props.userDetails &&
          this.props.loggedInUser &&
          this.props.userDetails.username === this.props.loggedInUser.username
        }
        showLoginModal={this.props.showLoginModal}
      />
    ) : this.props.userError ? (
      <p></p>
    ) : this.props.user ? (
      <div className="user-details">Loading...</div>
    ) : (
      ""
    );

    return (
      <div>
        {userInfo}
        <PlaylistsList
          playlists={this.props.playlists}
          count={this.props.count}
          hasNext={this.hasNext}
          page={this.props.page}
          query={this.props.playlistSearch}
          perPage={this.props.perPage}
          userDetails={this.props.userDetails}
          userError={this.props.userError}
          loadPlaylist={this.props.loadPlaylist}
          loggedInUser={this.props.loggedInUser}
          handlePreviousClick={this.handlePreviousClick}
          handleNextClick={this.handleNextClick}
          handleInputChange={this.handleInputChange}
          buildUrlFor={this.buildUrl}
        />
      </div>
    );
  }

  // just following the  links?
  _handleNextClick(e) {}
  _handlePreviousClick(e) {}

  _handleInputChange(e) {
    let q = e.target.value;

    var pageNumber = +this.props.page;
    var rootPath;
    if (this.props.userDetails) {
      rootPath = `/playlists/${this.props.userDetails.username}`;
    } else {
      rootPath = `/playlists`;
    }

    page.replace(`${rootPath}/?pq=${q}&page=0`);
  }

  _buildUrl(direction) {
    var query = [];
    if (this.props.playlistSearch) {
      query.push("pq=" + this.props.playlistSearch);
    }

    if (direction === "new") {
      let newNew = +this.props.page - 1;
      query.push("page=" + (newNew < 0 ? 0 : newNew));
    } else if (direction === "old") {
      if (this.hasNext()) {
        query.push("page=" + (+this.props.page + 1));
      } else {
        query.push("page=" + (this.props.page || 0));
      }
    }

    var user = this.props.userDetails
      ? this.props.userDetails.username + "/"
      : "";

    return "/playlists/" + user + "?" + query.join("&");
  }

  _hasNext() {
    return (this.props.page + 1) * this.props.perPage < this.props.count;
  }
}

module.exports = Playlists;
