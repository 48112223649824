var React = require("react");
var ModalMixin = require("./Modal");
var ReactCSSTransitionGroup = require("react-transition-group/CSSTransitionGroup");

module.exports = React.createClass({
  mixins: [ModalMixin],
  modalId: "shareModal",

  getInitialState: function () {
    return {
      embedLock: false,
      linkLock: false,
      autoSaveLock: false,
    };
  },

  // Content methods
  getTitle: function () {
    return "Share your music with the world!";
  },

  getPlaylistUrl: function (lock, embed) {
    var plUrl =
      "https://great.dj/" + (embed ? "embed/" : "") + this.props.playlistId;

    if (lock) {
      plUrl += "/" + this.props.playlistVersion;
    }

    if (!embed && this.state.autoSaveLock) {
      plUrl += "?autosave";
    }

    return plUrl;
  },

  getTextAreaValue: function () {
    return (
      '<iframe src="' +
      this.getPlaylistUrl(this.state.embedLock, true) +
      '" width="325" height="530" frameborder="0"></iframe>'
    );
  },

  getInputValue: function () {
    return this.getPlaylistUrl(this.state.linkLock);
  },

  handleLockVersionChange: function (e) {
    var stateKey = e.target.attributes["data-attr"].value,
      newState = {};

    newState[stateKey] = e.target.checked;

    this.setState(newState);
  },

  handleAutoSaveChange: function (e) {
    this.setState({
      autoSaveLock: e.target.checked,
    });
  },

  getBody: function () {
    var url = "https://great.dj/" + this.props.playlistId;
    console.log(url);
    var strWindowFeatures =
      "width=575,height=230,resizable," +
      "centerscreen,left=" +
      (window.innerWidth - 575) / 2 +
      ",top=" +
      (window.innerHeight - 230) / 2;

    var imgStyles = { height: 22, cursor: "pointer" };

    return (
      <div className="shareModalContent">
        <p>
          <strong>1.</strong> To share this playlist just copy the direct link
          below:
        </p>
        <input
          type="text"
          value={this.getInputValue()}
          className="block-input"
          readOnly
        />
        <div>
          <input
            type="checkbox"
            checked={this.state.linkLock}
            id="linkLockVersion"
            data-attr="linkLock"
            onChange={this.handleLockVersionChange}
          />
          <label htmlFor="linkLockVersion">
            Lock to current playlist version?
          </label>
        </div>
        <div>
          <input
            type="checkbox"
            checked={this.state.autoSaveLock}
            id="autosave"
            onChange={this.handleAutoSaveChange}
          />
          <label htmlFor="autosave">With Auto Save enabled by default?</label>
        </div>

        <p>
          <strong>2.</strong>To embed the playlist in your site, blog, etc, copy
          the code below:
        </p>
        <textarea
          className="block-input"
          value={this.getTextAreaValue()}
          readOnly
        ></textarea>
        <input
          type="checkbox"
          checked={this.state.embedLock}
          id="embedLockVersion"
          data-attr="embedLock"
          onChange={this.handleLockVersionChange}
        />
        <label htmlFor="embedLockVersion">
          Lock to current playlist version?
        </label>
      </div>
    );
  },

  getFooter: function () {
    return "";
  },
});
