var React = require("react");
var page = require("page");

if (process.env.BROWSER) {
  require("../less/playlist-block.less");
}

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    hour = d.getHours(),
    minute = d.getMinutes();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  if (hour < 10) hour = "0" + hour;
  if (minute < 10) minute = "0" + minute;

  return d.toDateString();
  //return [year, month, day].join("-") + ` ${hour}:${minute}`;
}

var PlaylistBlock = React.createClass({
  render: function () {
    var imgSrc =
      this.props.playlist.image ||
      this.props.playlist.thumbnail ||
      this.getImageUrlForVideo(
        this.props.playlist.playlist
          ? this.props.playlist.playlist[0]
          : undefined
      );

    var matchQueryElm = "";
    if (this.props.query) {
      var matchQuery = this.props.playlist.id,
        matchType = "id";

      var test = new RegExp(this.props.query, "gi");

      if (this.props.playlist.playlist) {
        for (var i = this.props.playlist.playlist.length - 1; i >= 0; i--) {
          if (this.props.playlist.playlist[i].snippet.title.match(test)) {
            matchType = "song";
            matchQuery = this.props.playlist.playlist[i].snippet.title;
            break;
          }
        }
      }

      var title = this.props.playlist.settings.title;
      if (title && title.match(test)) {
        matchType = "title";
        matchQuery = title;
      }

      matchQueryElm = (
        <p className="playlist-match-query">
          Matches {matchType}: <span className="em">{matchQuery}</span>
        </p>
      );
    }

    var artists = this.props.playlist.artists
      .slice(0, 3)
      .map(function (artist) {
        return artist.name;
      });
    var plInfo = artists.length ? artists.join(", ") + "..." : "";

    var infoStyle = matchQueryElm ? { display: "none" } : {};

    var titleElm = this.props.playlist.settings.title ? (
      this.props.playlist.username ? (
        <h3 className="playlist-title">
          {this.props.playlist.settings.title}
          <small>
            by <span>{this.props.playlist.username}</span>
          </small>
        </h3>
      ) : (
        <h3 className="playlist-title">{this.props.playlist.settings.title}</h3>
      )
    ) : (
      <p className="playlist-summary" style={infoStyle}>
        <span className="fa fa-microphone"></span> {plInfo}
      </p>
    );

    var previewLoadComponent = this.props.showPreviewLoad ? (
      <span
        title="Preview"
        data-tooltip="Preview playlist"
        className="playlist-open tooltip-right desktop"
        onClick={this.handleLoadPlaylistClick}
      >
        <i className="fa fa-play-circle"></i>
      </span>
    ) : (
      ""
    );

    var playlistLinkTitle =
      "Load playlist " +
      (this.props.playlist.settings.title || this.props.playlist.id);
    var created = this.props.playlist.created;

    return (
      <a
        href={"/" + this.props.playlist.id}
        title={playlistLinkTitle}
        className="playlist-block block"
        onClick={this.handleGoToPlaylist}
        target="_self"
      >
        <div className="image-container">
          <img src={imgSrc} className="playlist-img" />
          <div className="info">
            <span className="playlist-length">
              <i className="fa fa-music"></i>
              {this.props.playlist.size ||
                (this.props.playlist.playlist
                  ? this.props.playlist.playlist.length
                  : 0)}
            </span>
            {this.props.playlist.hits ? (
              <span className="playlist-hits">
                <i className="fa fa-eye"></i> {this.props.playlist.hits}
              </span>
            ) : (
              ""
            )}
            {previewLoadComponent}
          </div>
        </div>
        <div className="text">
          {matchQueryElm}
          {titleElm}
          {/* 
          <p className="playlist-summary" style={infoStyle}><span className="fa fa-microphone"></span> {plInfo}</p>
          <h3 className="playlist-mobile-title mobile">{this.props.playlist.settings.title}</h3>
          <p className="playlist-id desktop">great.dj{this.getPlaylistUrl()}</p>
          */}
          <p className="playlist-created">Created: {formatDate(created)}</p>
        </div>
      </a>
    );
  },

  getRandomVideo: function (pl) {
    if (!pl) return;
    return pl[Math.floor(Math.random() * pl.length)];
  },

  getImageUrlForVideo: function (video) {
    if (!video) return;
    return "http://img.youtube.com/vi/" + video.id + "/0.jpg";
  },

  handleLoadPlaylistClick: function (e) {
    e.preventDefault();
    this.props.loadPlaylist(this.props.playlist.id, undefined, true);
  },

  handleGoToPlaylist: function (e) {
    e.preventDefault();

    if (
      e.target.classList.contains("fa-play-circle") ||
      e.target.classList.contains("playlist-open")
    )
      return;

    page(this.getPlaylistUrl());
  },

  getPlaylistUrl() {
    return "/" + this.props.playlist.id;
  },
});

module.exports = PlaylistBlock;
