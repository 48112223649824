var cookie = require("cookie");
var uuid = require("uuid");
var page = require("page");

var AppDispatcher = require("../dispatcher/AppDispatcher");
var EventEmitter = require("events").EventEmitter;
var Constants = require("../constants/AppConstants");
var CHANGE_EVENT = Constants.CHANGE_EVENT;
var objectAssign = require("object-assign");
var _ = require("underscore");

var toast = require("react-toastify").toast;

var _playlist = window.playlist ? window.playlist.playlist || [] : [];
var _playlistSize =
  window.playlist && window.playlist.playlist
    ? window.playlist.playlist.length
    : 0;
var _playlistId = window.playlist ? window.playlist.plId : undefined;
var _playlistSettings = window.playlist ? window.playlist.settings : {};
var _playlistVersion = window.playlist ? window.playlist.version : undefined;
var _position = -1;
var _sync = false;
var _popular = window && window.DATA ? window.DATA.popularPlaylists || [] : [];
var _partyClients = 0;
var _ts;
var _dts;
var _myPlaylists = [];
var _myPlaylistsSummary =
  window && window.DATA ? window.DATA.userPlaylists || [] : [];
var _user;
var _paused = false;

function saved(plId, v, settings, data) {
  var updating = false;

  if (plId !== _playlistId) {
    // new playlist
    _playlistSettings = {};
  } else {
    updating = true;
  }

  _playlistId = plId;
  _playlistVersion = v;
  _.extend(_playlistSettings, settings);
  _playlistSize = data.playlist.length;
  _user = data.username;
}

function loaded(data, params) {
  /*
   this is because how loading a playlist is a two step process now:
   1. set the url with the id
   2. load the data
   3. fill in the version
   if we're loading some playlist id that's not the one we're asking for, invalidate
   the request as it's probably something old. otherwise infinite cycles might happen
  */
  var path = window.location.pathname.split("/");
  var urlId = path[1];
  if (urlId === "embed") urlId = path[2];
  if (data.username && urlId === data.username) {
    urlId = path[1] + "/" + path[2];
  }

  /*
  console.log(data.playlistId, urlId)
  if(data.playlistId !== urlId && urlId !== 'playlists') {
    throw new Error(`Playlist id ${data.playlistId} did not match urlId ${urlId}`);
  }
  */

  _playlistId = data.playlistId;
  _playlist = data.playlist;
  _playlistSize = !_sync ? data.playlist.length : _playlistSize;
  _playlistVersion = data.v !== undefined ? data.v : _playlistVersion;
  _position = data.position === undefined ? _position : data.position; // zero is a valid value!
  _playlistSettings = data.playlistSettings
    ? data.playlistSettings
    : _playlistSettings;
  _ts = data.ts;
  _dts = data.deltats;
  _user = data.username;
  _paused = false;

  console.log("playlist loaded", _playlistId, _playlistVersion);

  if (window.app && !window.app.isEmbed && !params.preventRedirect) {
    var versionStr = _playlistVersion === undefined ? "" : _playlistVersion;
    // updates the url
    // playlist version empty in case of joining a party
    page.redirect(
      "/" + _playlistId + "/" + versionStr + window.location.search
    );
  }
}

function changed(data) {
  var changes = data.changes;
  if (changes.id) _playlistId = changes.id;
  if (changes.playlist) {
    _playlist = changes.playlist;
  }

  if (changes.position !== undefined) {
    _position = changes.position;
    _paused = false;
  }
  if (changes.settings) _.extend(_playlistSettings, changes.settings);
}

function setPlaylistTo(playlist, raw) {
  _playlist = playlist;
  _paused = false;
  if (_position === -1) {
    _position = 0;
  }
}

function setSyncTo(sync) {
  _sync = sync;
}

function setPopularPlaylists(pls) {
  _popular = pls;
}

function setPartyClients(c) {
  _partyClients = c;
}

function reset() {
  _playlist = [];
  _playlistId = undefined;
  _playlistVersion = undefined;
  _position = -1;
  _paused = false;
}

function setPlaylistId(id) {
  _playlistId = id;
}

function initializeCookie() {
  var newCookie = { uuid: uuid.v4(), myPlaylists: [] };
  document.cookie = cookie.serialize("greatdj", JSON.stringify(newCookie));
}

function _parseCookie() {
  var cookies = cookie.parse(document.cookie);
  var obj = cookies.greatdj ? JSON.parse(cookies.greatdj) : {};

  return obj.myPlaylists || [];
}

function updateMyPlaylists(id, hash, newId) {
  var cookies = cookie.parse(document.cookie);
  var obj = cookies.greatdj ? JSON.parse(cookies.greatdj) : undefined;

  if (!obj || !Object.keys(obj)) {
    obj = { uuid: uuid.v4(), myPlaylists: [] };
  }

  if (!newId) {
    _myPlaylists = _myPlaylists.concat([[id, hash]]);
  } else {
    let tmp = _myPlaylists.slice(0);

    for (var i = 0; i < tmp.length; i++) {
      if (tmp[i][0] === id) {
        tmp[i][0] = newId;
        break;
      }
    }

    _myPlaylists = tmp;
  }

  obj.myPlaylists = _myPlaylists;

  var theFuture = new Date();
  theFuture.setYear(theFuture.getFullYear() + 10);
  document.cookie = cookie.serialize("greatdj", JSON.stringify(obj), {
    expires: theFuture,
    path: "/",
  });
}

function resetMyPlaylists() {
  clearCookie();
}

function clearCookie() {
  var yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  document.cookie = cookie.serialize("greatdj", "", {
    expires: yesterday,
  });
}

function setMyPlaylistsSummary(data) {
  _myPlaylistsSummary = data;
}

function updatePlaylistSettings(id, key, value, fn) {
  if (key !== "title" && key !== "id") return fn(false);

  let found = !!_myPlaylistsSummary
    .filter((p) => p.id === id)
    .concat(_popular.filter((p) => p.id === id)).length;

  if (!found) return fn(false);

  if (key === "title") {
    _myPlaylistsSummary = _myPlaylistsSummary.map((p) => {
      if (p.id === id) {
        p.title = value;
        p.settings.title = value;
      }
      return p;
    });

    _popular = _popular.map((p) => {
      if (p.id === id) {
        p.title = value;
        p.settings.title = value;
      }
      return p;
    });
  } else if (key === "id") {
    _myPlaylistsSummary = _myPlaylistsSummary.map((p) => {
      if (p.id === id) {
        p.id = value;
      }
      return p;
    });

    _popular = _popular.map((p) => {
      if (p.id === id) {
        p.id = value;
      }
      return p;
    });
  }

  return fn(true);
}

function pausePlayback() {
  _paused = true;
}

function playPlayback() {
  _paused = false;
}

var PlaylistStore = objectAssign({}, EventEmitter.prototype, {
  setMyPlaylists: function (p) {
    _myPlaylists = p;
  },

  parseCookie: function () {
    return _parseCookie();
  },

  getMyPlaylists: function () {
    return _myPlaylists;
  },

  resetMyPlaylists: function () {
    resetMyPlaylists();
  },

  updateMyPlaylists: function (id, hash, newId) {
    updateMyPlaylists(id, hash, newId);
  },

  getPlaylist: function () {
    return _playlist;
  },

  getPlaylistSize: function () {
    return _playlistSize;
  },

  getPlaylistId: function () {
    return _playlistId;
  },

  getPlaylistSettings: function () {
    return _playlistSettings;
  },

  getPosition: function () {
    return _position;
  },

  getSync: function () {
    return _sync;
  },

  getPopularPlaylists: function () {
    return _popular;
  },

  getPartyClients: function () {
    return _partyClients;
  },

  getPlaylistTs: function () {
    return _ts;
  },

  getPlaylistDelta: function () {
    return _dts;
  },

  getPlaylistVersion: function () {
    return _playlistVersion;
  },

  getMyPlaylistsSummary: function () {
    return _myPlaylistsSummary;
  },

  getPlaylistUser: function () {
    return _user;
  },

  getPaused: function () {
    return _paused;
  },

  //

  emitChange: function () {
    this.emit(CHANGE_EVENT);
  },

  addChangeListener: function (callback) {
    this.on(CHANGE_EVENT, callback);
  },

  removeChangeListener: function (callback) {
    this.removeListener(CHANGE_EVENT, callback);
  },

  replaceUrl() {
    page.replace(
      "/" + _playlistId + "/" + _playlistVersion + window.location.search
    );
  },
});

// Register to handle all updates
AppDispatcher.register(function (payload) {
  var action = payload.action;

  switch (action.actionType) {
    case Constants.PLAYLIST_SAVED:
      if (action.response === Constants.request.PENDING) {
        /// tururur
      } else if (action.response === Constants.request.ERROR) {
        //console.log('Error saving playlist:', action.queryParams.message);
        let msg =
          action.queryParams.status === 403
            ? "Playlist is private."
            : "Unknown error";
        toast.error("Error saving playlist: " + msg, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (action.response.playlistId) {
        saved(
          action.response.playlistId,
          action.response.v,
          action.response.settings,
          action.response.playlist
        );

        // if(action.response.callback){
        //   log('calling callback with ', action.response.playlistId);
        //   action.response.callback(action.response.playlistId);
        // }

        PlaylistStore.emitChange();

        PlaylistStore.replaceUrl();
      }
      break;

    case Constants.PLAYLIST_LOADED:
      if (action.response === Constants.request.PENDING) {
        // console.log('setting playlistid to', action.queryParams.id)
        // setPlaylistId(action.queryParams.id);
        // PlaylistStore.emitChange();
        /// tururur wait for iit
      } else if (action.response === Constants.request.ERROR) {
        toast.error("Sorry, couldn't find that playlist.", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (action.response.playlistId) {
        console.log("Playlist loaded", action.response);
        loaded(action.response, action.queryParams);
        PlaylistStore.emitChange();
      }
      break;

    case Constants.PLAYLIST_CLEAR_CURRENT:
      reset();
      PlaylistStore.emitChange();
      break;

    case Constants.SYNC_OFF:
      console.log("turning off sync");
      setSyncTo(false);
      PlaylistStore.emitChange();
      break;

    case Constants.SYNC_ON:
      console.log("turning sync on");
      setSyncTo(true);
      PlaylistStore.emitChange();
      break;

    case Constants.PLAYLIST_CHANGE:
      console.log("playlist change", action.response);
      changed(action.response);
      PlaylistStore.emitChange();
      break;

    case Constants.UNSET_PLAYLIST_ID:
      _playlistId = undefined;
      PlaylistStore.emitChange();
      break;

    // case Constants.SET_PLAYLIST_ID:
    //   _playlistId = action.response.id;
    //     history.pushState(null, null, '/'+action.response.id);
    //   PlaylistStore.emitChange();
    //   break;

    case Constants.POPULAR_PLAYLISTS:
      setPopularPlaylists(action.response.playlists);
      PlaylistStore.emitChange();
      break;

    case Constants.PARTY_PRESENCE:
      setPartyClients(action.response.clients);
      PlaylistStore.emitChange();
      break;

    case Constants.RESET:
      reset();
      PlaylistStore.emitChange();
      break;

    case Constants.UPDATE_MY_PLAYLISTS:
      PlaylistStore.updateMyPlaylists(action.id, action.hash, action.newId);
      PlaylistStore.emitChange();
      break;

    case Constants.DESCRIBE_MY_PLAYLISTS:
      setMyPlaylistsSummary(
        action.response ? action.response.data : action.data
      );
      PlaylistStore.emitChange();
      break;

    case Constants.RESET_COOKIE:
      PlaylistStore.resetMyPlaylists();
      break;

    case Constants.SET_MY_PLAYLISTS:
      PlaylistStore.setMyPlaylists(action.playlists);
      PlaylistStore.emitChange();
      break;

    case Constants.SET_PLAYLIST:
      setPlaylistTo(
        action.response.items.map((v) => v[0]),
        action.response.result
      );
      PlaylistStore.emitChange();
      break;

    case Constants.CLEAR_MY_PLAYLISTS:
      setMyPlaylistsSummary([]);
      PlaylistStore.emitChange();
      break;

    case Constants.UPDATE_PLAYLIST_SETTINGS:
      updatePlaylistSettings(action.id, action.key, action.value, (changed) => {
        if (changed) PlaylistStore.emitChange();
      });
      break;

    case Constants.PAUSE:
      pausePlayback();
      PlaylistStore.emitChange();
      break;

    case Constants.PLAY:
      playPlayback();
      PlaylistStore.emitChange();
      break;

    default:
      return true;
  }

  // This often goes in each case that should trigger a UI change. This store
  // needs to trigger a UI change after every view action, so we can make the
  // code less repetitive by putting it here.  We need the default case,
  // however, to make sure this only gets called after one of the cases above.

  return true; // No errors.  Needed by promise in Dispatcher.
});

module.exports = PlaylistStore;
