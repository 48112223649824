var React = require("react");
var Api = require("../utils/Api");

var SearchResults = React.createClass({
  getInitialState: () => ({ expanded: false }),

  handleVideoEnqueue: function (video, e) {
    e.preventDefault();

    if (
      e.target.attributes["data-action"] &&
      e.target.attributes["data-action"].value === "play"
    ) {
      return;
    }

    this.props.enqueueHandler(video);

    return false;
  },

  handlePlayNow: function (video) {
    this.props.playNowHandler(video);
    return false;
  },

  handlePlaylistSelect: function (pl, e) {
    e.preventDefault();

    Api.getVideosFromYoutubePlaylist(pl.id, (videos) => {
      this.props.playlistEnqueueHandler(videos);
    });

    return false;
  },

  expandMobileSearchResults: function (e) {
    this.setState({ expanded: true });
  },

  addAllResults(e) {
    e.preventDefault();

    this.props.addAllResultsHandler(
      this.props.videos.filter((vid) => vid.source !== "youtube-playlist")
    );
  },

  render: function () {
    let { expanded } = this.state;
    let videos = [];
    let playlists = [];

    this.props.videos.forEach((res, i) => {
      var rowClassName = "row " + (i % 2 ? "odd" : "even");

      var pos = this.props.getPlaylistPosition(res.id);

      if (pos > 0) {
        rowClassName += " in-queue";
      } else if (pos === 0) {
        rowClassName += " playing";
      } else if (pos < 0) {
        rowClassName += " played";
      }

      if (res.source === "youtube-playlist") {
        // add to playlists
        rowClassName += " playlist";
        playlists.push(
          <td className="results-playlist" key={res.id}>
            <a onClick={this.handlePlaylistSelect.bind(this, res)} href="#">
              <img className="thumbnail" src={res.snippet.thumbnail} />
              <i
                className={"source fab fa-youtube"}
                style={{ fontSize: "1.25em" }}
              ></i>
              {res.snippet.title}
            </a>
          </td>
        );
      } else {
        // add to videos
        var sourceIcon = res.source === "soundcloud" ? "soundcloud" : "youtube";
        videos.push(
          <tr
            key={res.id}
            className={rowClassName}
            onClick={this.handleVideoEnqueue.bind(this, res)}
          >
            <td className="imgTd">
              <a href="#">
                <img className="thumbnail" src={res.snippet.thumbnail} />
              </a>
            </td>
            <td className="descTd">
              <a href="#">{res.snippet.title}</a>
              <i
                style={{ fontSize: "1.25em" }}
                className={"source fab fa-" + sourceIcon}
              ></i>
              {res.source === "youtube-playlist" ? (
                <span className="playlist-label">Youtube playlist</span>
              ) : (
                ""
              )}
              <p className="entry-state in-queue">
                <i className="fa" />
                In playlist
              </p>
              <p className="entry-state playing">
                <i className="fa" />
                Currently playing!
              </p>
              <p className="entry-state played">
                <i className="fa" />
                Already played
              </p>
              <div className="mobile">
                <button ref="enq" className="primary">
                  Add to Queue
                </button>
                {res.source !== "youtube-playlist" ? (
                  <button
                    ref="play"
                    data-action="play"
                    onClick={this.handlePlayNow.bind(this, res)}
                  >
                    Play now
                  </button>
                ) : (
                  ""
                )}
              </div>
            </td>
            <td className="buttonTd desktop">
              <button ref="enq" className="primary">
                Add to Queue
              </button>
              {res.source !== "youtube-playlist" ? (
                <button
                  ref="play"
                  data-action="play"
                  onClick={this.handlePlayNow.bind(this, res)}
                >
                  Play now
                </button>
              ) : (
                ""
              )}
            </td>
          </tr>
        );
      }
    });

    return (
      <div className={`results-container ${expanded ? "expanded" : ""}`}>
        {playlists.length && !this.props.sync ? (
          <div className="results-playlists-container">
            <table className="results-playlists">
              <tr>
                <th colSpan={playlists.length}>
                  <h4 className="results-title">Playlists</h4>
                </th>
              </tr>
              <tr className="results-playlists-tr">{playlists}</tr>
            </table>
          </div>
        ) : (
          ""
        )}
        <table className="results">
          <thead></thead>
          <tbody>
            <tr>
              <td colSpan="3" style={{ padding: 0 }}>
                <h4 className="results-title">Tracks</h4>
                <button className="add-all-btn" onClick={this.addAllResults}>
                  Add all results
                </button>
              </td>
            </tr>
            {videos}
          </tbody>
        </table>
        <div className={`mobile-expand`}>
          <button onClick={(e) => this.expandMobileSearchResults(e)}>
            Show more <i className="fa fa-caret-down" />
          </button>
        </div>
      </div>
    );
  },
});

module.exports = SearchResults;
