var AppDispatcher = require('../dispatcher/AppDispatcher');
var Constants = require('../constants/AppConstants');
var Api = require('../utils/Api.js');

module.exports = {
  updateUsername: function(username) {
    AppDispatcher.handleViewAction({
      actionType: Constants.USER_UPDATE_USERNAME,
      username
    });
  },

  loadUser: function(id){
    Api.loadUser(id, (user) => {
      if(user){
        AppDispatcher.handleViewAction({
          actionType: Constants.USER_LOADED,
          user
        });
      }
    })
  },

  unsetFirstLogin: function(){
    Api.unsetUserFirstLogin(() => {
      AppDispatcher.handleViewAction({
        actionType: Constants.USER_UNSET_FL
      });
    });
  },

  unsetUser: function(){
    AppDispatcher.handleViewAction({
      actionType: Constants.USER_UNSET
    });
  },

  logout: function(){
    Api.logout();
  },

  authGoogleToken: function(token, cb){
    return Api.authGoogleToken(token, cb);
  },

  upsertUser: function(user, cb){
    return Api.upsertUser(user, cb);
  },

  setUser: function(user){
    AppDispatcher.handleViewAction({
      actionType: Constants.USER_LOADED,
      user
    });
  }
}
