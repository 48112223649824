window.React = require('react');
var React = window.React;
const { toast, Slide  } = require('react-toastify');
require('react-toastify/dist/ReactToastify.css');
var secrets = require('./constants/secrets.js'),
    base64 = require('js-base64').Base64,
    queryString = require('query-string');

// some things should just work...
NodeList.prototype.forEach = Array.prototype.forEach;
HTMLCollection.prototype.indexOf = Array.prototype.indexOf;
HTMLCollection.prototype.forEach = Array.prototype.forEach;

// Sentry error tracking
if(window && window.Raven){
  window.Raven.config('https://9e7522b02e334aa7a82a7dcbeccc73e7@sentry.io/232442').install()
}

if(version){
  window.VERSION = version;
}

// CustomEvent Polyfill for older browsers and IE
(function () {
  function CustomEvent ( event, params ) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent( 'CustomEvent' );
    evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
    return evt;
   }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
})();

try {
  window.DATA = JSON.parse(base64.decode(window.RAW) || '{}');
  window.playlist = JSON.parse(base64.decode(window._playlist) || '{}');
} catch(e){
  throw e;
}

// soundcloud api
SC.initialize({
  client_id: secrets.soundcloudKey
});

// service worker
let hasBeenOffline = false;
if ('serviceWorker' in navigator) {
  let swUrl = process.env.DEV ? '/service-worker.js' : '/service-worker.js';
  window.addEventListener('load', function() {
    navigator.serviceWorker.register(swUrl);
  });

  window.addEventListener('offline', function() {
    toast(`Looks like you're offline, please check your internet connection.`, {
      position: toast.POSITION.TOP_CENTER
    });
  });

  window.addEventListener('online', function() {
    if(hasBeenOffline){
      toast(`Back online, well done!`, {
        position: toast.POSITION.TOP_CENTER
      });
    }
  });

  if(navigator.onLine === false){
    hasBeenOffline = true;
    toast(`Looks like you're offline, please check your internet connection.`, {
      position: toast.POSITION.TOP_CENTER
    });
  }
}

// application wide initizalizations
window.app = {
	baseTitle: 'GREAT DJ!'
};

var setQueryStringValue = (key, val) => {
  var parsed = queryString.parse(location.search);
  parsed[key] = val;

  var baseUrl = location.pathname;
  var qStr = queryString.stringify(parsed);

  page.replace(baseUrl + (qStr ? '?' + queryString.stringify(parsed) : ''));
}

if(window._error){
  toast.error(window._error, {
    position: toast.POSITION.TOP_CENTER
  });
  window._error = '';
}
// Start App
const StateHandler = require('./components/StateHandler');
const AppMainView = require('./components/AppMainView');
const page = require('page');
const ReactDOM = require('react-dom');
const routes = {};

routes.index = function(props){
  var queryObj = queryString.parse(props.querystring) || {};
  var {first, second, third} = props.params;
  var user;
  var plId;
  var version;

  if(second && isNaN(+second)){
    // second is playlist id, first is user
    user = first;
    plId = user + '/' + second;
    version = third;
  } else {
    plId = first;
    version = second;
  }

  ReactDOM.render(
    <StateHandler
      playlistId={plId}
      version={version}
      search={queryObj.q}
      view={"search"}
      autoSave={Object.keys(queryObj).indexOf('autosave') > -1}
      setQueryStringValue={setQueryStringValue}
      party={queryObj.party}
    >
      <AppMainView />
    </StateHandler>
  , document.querySelector('#app'));
}

routes.playlists = function(props){
  var queryObj = queryString.parse(props.querystring);
  var user = props.params.user;
  var page = +queryObj.page;

  if(!page) { page = 0; }

  ReactDOM.render(
    <StateHandler
      playlistsPage={ page }
      playlistsUser = { user }
      view={"playlists"}
      playlistSearch={queryObj.pq}
      setQueryStringValue={setQueryStringValue}
      >
      <AppMainView />
    </StateHandler>
  , document.querySelector('#app'));
}

routes.notFound = function(){
  ReactDOM.render(<h1 style={{textAlign: 'center'}}>404 SORRY :(</h1>, document.querySelector('#app'));
}

page('/playlists', routes.playlists);
page('/playlists/:user', routes.playlists);
page('/:first?/:second?/:third?', routes.index);
page('*', routes.notFound);
page();
