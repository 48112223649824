const React = require("react");
const PropTypes = require("prop-types");

const listBaseStyles = {
  display: "none",
};

function bindToInstance(fns, instance) {
  fns.forEach((fn) => (instance[fn] = instance[fn].bind(instance)));
}

class Dropdown extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
    };

    bindToInstance(
      ["handleButtonClick", "handleOptionClick", "handleCaretClick"],
      this
    );
  }

  render() {
    const listStyles = {
      ...listBaseStyles,
      ...(this.state.open ? { display: "block" } : {}),
    };

    return (
      <div className="dropdown" style={this.props.style}>
        <div>
          <button
            type="button"
            className="button-container"
            onClick={this.handleButtonClick}
          >
            {this.props.children}
          </button>
          <button
            type="button"
            className="caret-container"
            onClick={this.handleCaretClick}
          >
            <i className="fa fa-caret-down"></i>
          </button>
        </div>
        <ul style={listStyles}>{this.buildOptions(this.props.options)}</ul>
      </div>
    );
  }

  buildOptions(opts) {
    return opts.map((opt, i) => (
      <li key={i}>
        <a
          href={opt.href}
          data-option={opt.ref}
          onClick={(e) => this.handleOptionClick(e, opt.ref)}
        >
          {opt.label}
        </a>
      </li>
    ));
  }

  handleButtonClick() {
    if (this.props.handleButtonClick) {
      this.props.handleButtonClick();
    } else {
      this.handleCaretClick();
    }
  }

  handleCaretClick() {
    this.setState({ open: !this.state.open });
  }

  handleOptionClick(e, ref) {
    this.props.handleOptionClick(e, ref);
    this.close();
  }

  close() {
    this.setState({ open: false });
  }

  open() {
    this.setState({ open: true });
  }
}

Dropdown.propTypes = {
  style: PropTypes.object,
  children: PropTypes.element.isRequired,
  options: PropTypes.array,
  handleButtonClick: PropTypes.func,
  handleOptionClick: PropTypes.func.isRequired,
};

module.exports = Dropdown;
