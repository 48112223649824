const React = require("react");
const PropTypes = require("prop-types");
const PlaylistBlock = require("./PlaylistBlock");

const PlaylistsList = function ({
  playlists,
  page,
  perPage,
  userDetails,
  userError,
  query,
  loadPlaylist,
  loggedInUser,
  handlePreviousClick,
  handleNextClick,
  handleInputChange,
  buildUrlFor,
  hasNext,
}) {
  let content;

  const renderUserElement = () => {
    return (
      <div className="user-playlists-intro horizontal-list-title">
        <h3 className="page-title pull-left">
          Playlists by {userDetails.username}{" "}
          <small> // Page {+page + 1}</small>
        </h3>
        <div className="pull-right">
          <a
            className="btn btn-underline space-right"
            href="javascript:history.go(-1)"
          >
            &lt; Go back
          </a>
          <a className="btn btn-underline" href="/playlists">
            View all playlists
          </a>
        </div>
      </div>
    );
  };

  if (userError) {
    return <h3 style={{ textAlign: "center" }}>User not found</h3>;
  } else if (!playlists) {
    content = <h3>Loading...</h3>;
  } else if (!playlists.length) {
    content = <h3>No playlists found, sorry!</h3>;
  } else {
    content = playlists.map(function (pl) {
      return (
        <PlaylistBlock
          key={pl.id}
          playlist={pl}
          query={query}
          loadPlaylist={loadPlaylist}
          showPreviewLoad={true}
        />
      );
    });
  }

  const previousPageClass = +page === 0 ? "disabled" : "";
  let nextPageClass = hasNext() ? "" : "disabled";
  nextPageClass += " pull-right";

  return (
    <div className="results-playlists horizontal-list">
      {userDetails ? (
        renderUserElement()
      ) : (
        <div className="horizontal-list-title">
          <h3 className="page-title pull-left">
            All playlists<small> // Page {+page + 1}</small>
          </h3>
          <div className="results-playlist-controls">
            <input
              type="text"
              placeholder="Search by playlists, songs or artists..."
              onChange={handleInputChange}
              defaultValue={query}
            />

            {loggedInUser ? (
              <span>
                <a
                  className="btn btn-underline space-right"
                  href="javascript:history.go(-1)"
                >
                  &lt; Go back
                </a>
                <a
                  className="btn btn-underline"
                  href={"/playlists/" + loggedInUser.username}
                >
                  View your playlists
                </a>
              </span>
            ) : (
              <a className="btn btn-underline" href="javascript:history.go(-1)">
                &lt; Go back
              </a>
            )}
          </div>
        </div>
      )}
      <div className="blocks-container">{content}</div>
      <div className="playlist-pagination">
        <a
          href={buildUrlFor("new")}
          onClick={(e) => {
            handlePreviousClick ? handlePreviousClick(e) : undefined;
          }}
          className={previousPageClass}
        >
          &lt; Newer
        </a>

        <a
          href={buildUrlFor("old")}
          onClick={(e) => (handleNextClick ? handleNextClick(e) : undefined)}
          className={nextPageClass}
        >
          Older &gt;
        </a>
      </div>
    </div>
  );
};

PlaylistsList.propTypes = {
  playlists: PropTypes.array,
  page: PropTypes.number,
  query: PropTypes.string,
  loadPlaylist: PropTypes.func,
  loggedInUser: PropTypes.object,
  userDetails: PropTypes.object,
  handlePreviousClick: PropTypes.func,
  handleNextClick: PropTypes.func,
  handleInputChange: PropTypes.func,
  buildUrlFor: PropTypes.func,
};

module.exports = PlaylistsList;
