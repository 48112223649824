var React = require("react");

var PlaylistBlock = require("./PlaylistBlock");
var SearchActions = require("../actions/SearchActions.js");
var PlaylistActions = require("../actions/PlaylistActions.js");

var isMobile = require("ismobilejs");

// from media-queries.less
const screenSizes = {
  xlScreen: 1500,
  lgScreen: 1300,
  tabletScreen: 1000,
  mobileScreen: 490,
};

var IntroComponent = React.createClass({
  getInitialState: function () {
    return {
      windowWidth: window.innerWidth,
    };
  },

  componentDidMount: function () {
    window.addEventListener("resize", this.handleResize, false);
    SearchActions.refreshRecentTerms();
    PlaylistActions.getPopularPlaylists();
  },

  componentWillUnmount: function () {
    window.removeEventListener("resize", this.handleResize, false);
  },

  handleResize() {
    this.setState({ windowWidth: window.innerWidth });
  },

  render: function () {
    if (this.props.isPlaylistLoaded && isMobile.phone) {
      return <span />;
    }

    //@todo deprecate this check
    var recentSearchesArr =
      this.props.recentTerms && this.props.recentTerms.length
        ? this.props.recentTerms
        : [];

    var recentSearches = recentSearchesArr.map(function (term, i) {
      var q = encodeURIComponent(term);
      if (term.length > 35) {
        term = term.substring(0, 35) + "...";
      }
      return (
        <li key={"search-" + i}>
          <a href={"?q=" + q} onClick={this.setQuery}>
            {term}
          </a>
        </li>
      );
    }, this);

    recentSearches.reverse();

    var popPlaylistsArr = this.props.popularPlaylists || [];
    var popularPlaylists = this.getPlaylistPreview(popPlaylistsArr);
    var titlesContainer = !this.props.isPlaylistLoaded
      ? this.renderTitlesContainer()
      : "";

    return (
      <div className="results-container results-intro">
        <div className="intro">
          {titlesContainer}

          {this.props.myPlaylistsSummary &&
          this.props.myPlaylistsSummary.length ? (
            this.renderMyPlaylists()
          ) : (
            <div></div>
          )}

          <div className="trending-playlists horizontal-list">
            <div className="horizontal-list-title">
              <h3 className="pull-left">Trending playlists</h3>
              <div className="pull-right">
                <a
                  className="btn btn-underline"
                  href="/playlists/"
                  alt="View all playlists"
                  title="View all playlists"
                >
                  View all playlists
                </a>
              </div>
            </div>
            <div className="blocks-container"> {popularPlaylists} </div>
          </div>

          <div className="features horizontal-list desktop">
            <div className="box desktop" id="features">
              <h3>
                <i className="fa fa-save"></i>
                <span>Save and Share your playlists</span>
              </h3>
              <p>
                Save your playlist and it will generate a unique public URL that
                you can share with the world! You can also get a playlist's
                embed code if you want to include it on your site/blog/etc.
              </p>
            </div>
            <div className="box desktop">
              <h3>
                <i className="fa fa-refresh"></i>
                <span>Party Mode</span>
              </h3>
              <p>
                If you enable <strong>Party Mode</strong>™ on multiple devices
                connected to the same playlist, they will all be in sync. You
                can use phone to add/remove songs and to change what's currently
                playing your computer!
              </p>
            </div>

            <div className="box desktop">
              <h3>
                <i className="fa fa-bookmark"></i>
                <span>Chrome Extension</span>
              </h3>
              <p>
                Download our{" "}
                <a
                  href="https://chrome.google.com/webstore/detail/greatdj/fobgllhmkmfdjnboijodmohifllnhigc"
                  target="_blank"
                >
                  <strong>updated Chrome Extension</strong>
                </a>{" "}
                and you'll never lose a playlist again! All the playlists you
                create or access will be available through the extension, for
                maximum convenience.
              </p>
            </div>
          </div>

          <div className="recent-searches horizontal-list">
            <h3>Some recent searches to get you going:</h3>
            <ul> {recentSearches} </ul>
          </div>
        </div>
      </div>
    );
  },

  renderTitlesContainer: function () {
    return (
      <div className="titles-container">
        <div>
          <h1>The easiest way to create, save and share music playlists.</h1>

          <p className="subtitle">
            Combine your favourite tracks from
            <span className="black" style={{ fontSize: "1.33em" }}>
              YouTube <i className="fab fa-youtube"></i>
            </span>{" "}
            and
            <span className="black" style={{ fontSize: "1.33em" }}>
              Soundcloud <i className="fab fa-soundcloud"></i>
            </span>
          </p>
          {/* soon <p className="subtitle">Import your playlists from<span className="black">Spotify</span></p> */}
          <p className="subtitle">
            Collaborate in realtime with your friends in
            <span className="black">Party Mode</span>
          </p>
        </div>
      </div>
    );
  },

  renderMyPlaylists() {
    var myLatest = this.props.myPlaylistsSummary.slice(0);
    var myPlaylistsBlock = this.getPlaylistPreview(myLatest);
    var viewAllLink =
      this.props.user && this.props.user.username ? (
        <div className="pull-right">
          <a
            className="btn btn-underline"
            href={`/playlists/${this.props.user.username}`}
          >
            View your playlists
          </a>
        </div>
      ) : (
        ""
      );

    return (
      <div className="my-playlists horizontal-list">
        <div className="horizontal-list-title">
          <h3>Your playlists</h3>
          {viewAllLink}
        </div>
        <div className="blocks-container"> {myPlaylistsBlock} </div>
      </div>
    );
  },

  getPlaylistPreview(playlist) {
    var screenWidth = this.state.windowWidth;
    var slicedPlaylist = playlist;

    if (screenWidth > screenSizes.xlScreen) {
      // do nothing keep the 10
      slicedPlaylist = playlist.slice(0, 10);
    } else if (screenWidth > screenSizes.lgScreen) {
      // show 8 (4 x 2)
      slicedPlaylist = playlist.slice(0, 8);
    } else {
      // show 3 (3 x 3)
      slicedPlaylist = playlist.slice(0, 9);
    }

    return slicedPlaylist.map(function (pl, i) {
      return <PlaylistBlock playlist={pl} key={pl.id} />;
    }, this);
  },
});

module.exports = IntroComponent;
