var EventEmitter = require('events').EventEmitter;
var AppDispatcher = require('../dispatcher/AppDispatcher');
var Constants = require('../constants/AppConstants');
var CHANGE_EVENT = Constants.CHANGE_EVENT;
var assign = require('object-assign');

var user;
var userError;

if(window && window.DATA && window.DATA.playlistsUserDetails){
  user = window.DATA.playlistsUserDetails;
}

function setUser(_user){
  user = _user;
}

function setUserError(error){
  if(error === 404){
    userError = {
      err: 404
    };
  }
}

function unsetUser(){
  user = undefined;
  userError = undefined;
}

var UserDetailsStore = Object.assign({}, EventEmitter.prototype, {
  getUserDetails(){
    return user;
  },

  getUserError(){
    return userError;
  },

  emitChange: function(){
    this.emit(CHANGE_EVENT);
  },

  addChangeListener: function(callback) {
    this.on(CHANGE_EVENT, callback);
  },

  removeChangeListener: function(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  },

});


AppDispatcher.register(function(payload) {
  var action = payload.action;

  switch(action.actionType) {
    case Constants.USER_DETAILS_LOADED:
      unsetUser();
      setUser(action.user);
      UserDetailsStore.emitChange();
    break;
    case Constants.USER_DETAILS_USER_NOT_FOUND:
      unsetUser();
      setUserError(404);
      UserDetailsStore.emitChange();
    break;
    case Constants.USER_DETAILS_CLEAR:
      unsetUser();
      UserDetailsStore.emitChange();
    break;
  }

  return true;

});

module.exports = UserDetailsStore;
