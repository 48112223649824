var React = require('react');

var PlaylistItem = require('./PlaylistItem');
var AnimatedScroll = require('./AnimatedScroll');

var dom = require('../utils/dom');

var liHeight = 42 + 3;

function appendGhostTo(e, li, ul){
  var ghostItem = document.createElement('li');
  ghostItem.classList.add('ghost-playlist-item');

  ul.querySelectorAll('li.ghost-playlist-item').forEach(node => node.remove());

  if(li){
    // insert after
    var pos = +li.attributes['data-pos'].value;

    var nextLi = li.nextSibling;

    if(nextLi){
      // insert after current one
      ghostItem.setAttribute('data-pos', pos);
      ul.insertBefore(ghostItem, nextLi);
    } else {
      // insert at last position
      ghostItem.setAttribute('data-pos', pos + 1);
      ul.appendChild(ghostItem);
    }

  } else {
    // insert at top
    ghostItem.setAttribute('data-pos', -1);
    ul.insertBefore(ghostItem, ul.firstChild);
  }

}

var Playlist = React.createClass({
  mixins: [AnimatedScroll],

  getInitialState: function(){
    return {
      dragging: false,
      draggingPos: -1,
      draggingStart: -1
    }
  },

  getAutoScrollPosition: function(){
    return (liHeight * this.props.position) + 3;
  },

  componentDidMount: function(){
    this.refs.playlist.addEventListener('drop', this.handleDrop, false);

    this.refs.playlist.addEventListener('dragenter', this.handleDragEnter, false);
    this.refs.playlist.addEventListener('dragleave', this.handleDragLeave, false);
    this.refs.playlist.addEventListener('dragover', this.handleDragOver, false);

    this.refs.playlist.addEventListener('dragstart', this.handleDragStart, false);
    this.refs.playlist.addEventListener('dragend', this.handleDragEnd, false);

  },

  componentWillReceiveProps: function(newProps){

    if(!this.refs.playlist) return;

    // If deleting an item and playlist in condensed mode, got to disable the height transition otherwise
    // the element disappear straights away and the playlist shows one more top item (before the scroll/height animation kicks in)
    if(this.playlistCount > this.props.playlist.length && !this.props.playlistToggled && this.props.position){
      this.refs.playlist.style.transition = "none";
      this.refs.playlist.style.height = (liHeight * (this.props.playlist.length - this.props.position) + 6) + "px";
    } else {
      this.refs.playlist.style.transition = "height 250ms ease-out";
    }
  },

  componentDidUpdate: function(oldProps) {
    if(this.refs.playlist && !this.props.playlistToggled && this.props.playlist.length){
      this.ensureActiveSongOnTop();
    }

    var position = this.props.position > 0 ? this.props.position : 0;

    if(!this.props.playlist.length){
      this.refs.playlist.style.height = '0px';
    } else {
      this.refs.playlist.style.height = ((this.props.playlistToggled) ?
        liHeight * (+this.props.playlist.length) - 3 : liHeight * (this.props.playlist.length - position) - 3) + "px";
    }

  },

  ensureActiveSongOnTop: function(){
    var that = this;

    this.refs.playlist.style.height = (liHeight * (this.props.playlist.length - this.props.position) + 3)+"px";
    this.animatedScrollTo(this.refs.playlist, this.getAutoScrollPosition(), 275);
  },

  handlePlayNow: function(pos, video){
    this.props.handlePlayNow(pos, video);
    return false;
  },

  handleDeleteEntry: function(pos, video){
    this.props.handleDeleteEntry(pos, video);
    return false;
  },

  switchPlaylistItems: function(from, to){
    this.props.switchPlaylistItems(from, to);
  },

  /**
   * Drag and drop
   */

  handleDragStart: function(e){
    var pos = +e.target.attributes['data-pos'].value;
    e.dataTransfer.setData('text/plain', pos);

    this.setState({
      dragging: true,
      draggingPos: pos,
      draggingStart: pos
    });

    console.log('dragstart', this.state)

    /*
    setTimeout(function(){
      var li = dom.returnSelfOrParent(e.target, 'li');
      var ul = dom.returnSelfOrParent(li, 'ul');

      li.classList.add('dragged');
      appendGhostTo(e, li, ul);
    }, 10);
    */
  },

  handleDragEnd: function(e){
    console.log('dragend');
    e.preventDefault();
    this.setState({
      dragging: false,
      draggingPos: -1,
      draggingStart: -1
    });

    /*
    e.target.classList.remove('dragged');
    var list = dom.returnSelfOrParent(e.target, 'ul');
    list.querySelectorAll('li.ghost-playlist-item').forEach(node => node.remove());
    */
  },

  handleDragOver: function(e){
    if (e.preventDefault) {
      e.preventDefault(); // Necessary. Allows us to drop.
    }
    e.dataTransfer.dropEffect = 'move';  // See the section on the DataTransfer object.
    this.handleDragEnter(e);
    return false;
  },

  handleDragEnter: function(e){
    var targetLi = dom.returnSelfOrParent(e.target, 'li[draggable]');
    if(!targetLi) return;

    var liBounds = targetLi.getBoundingClientRect();
    var ul = dom.returnSelfOrParent(targetLi, 'ul');

    if(e.clientY < liBounds.top + (liBounds.height / 2)){
      // top 50%
      if(targetLi.previousSibling && targetLi.previousSibling.getAttribute('data-pos') === null) return;
      let pos = targetLi.previousSibling ? targetLi.previousSibling.getAttribute('data-pos') : -1;

      if(+pos !== this.state.draggingPos){
        this.setState({
          draggingPos: +pos
        });
      }

    } else {
      // bottom 50%
      if(targetLi.getAttribute('data-pos') === null) return;
      let pos = targetLi.getAttribute('data-pos');

      if(+pos !== this.state.draggingPos){
        this.setState({
          draggingPos: +pos
        });
      }

    }
  },

  handleDrop: function(e){
    e.preventDefault();
    if (e.stopPropagation) {
      e.stopPropagation(); // stops the browser from redirecting.
    }

    e.dataTransfer.dropEffect = 'move';

    this.switchPlaylistItems(this.state.draggingStart, this.state.draggingPos);
    this.setState({
      dragging: false,
      draggingPos: -1,
      draggingStart: -1
    });

    /*
    var target = dom.returnSelfOrParent(e.target, 'li[data-pos]');

    console.log(e.target)

    if(target) {
      console.log('switch', e.dataTransfer.getData('text/plain'), target.attributes['data-pos'].value, target)
      this.switchPlaylistItems(e.dataTransfer.getData('text/plain'), target.attributes['data-pos'].value);
    } else {
      console.log('bode', e.target)
    }
    */

    return false;
  },

  handleDragLeave: function(e){
    //e.target.parentElement.classList.remove('dragged-over');
  },

// End of drag and drop

  wasDelete: function(props, oldProps){
    return props.playlist.length < oldProps.playlist.length;
  },

  render: function(){
    if(!this.props.playlist) return null;

    this.playlistCount = this.props.playlist.length;

    let dragActive = this.state.dragging &&
     this.state.draggingStart !== this.state.draggingPos;

    let playlistElements = [];

    this.props.playlist.forEach(function(video, i){
      var key = video.videoId + '_' + i;
      var classNames = (i < this.props.position) ? 'old' :
        (i === this.props.position) ? 'active' : 'queued';

      if(dragActive && this.state.draggingPos === i - 1){
        playlistElements.push(<li className="ghost-playlist-item" key={"ghost-"+i}></li>)
      } 

      let liClassNames = '';
      if(dragActive && this.state.draggingStart === i){
        liClassNames += ' dragged';
      }

      playlistElements.push(
        <PlaylistItem
          video={video}
          position={i}
          classNames={classNames}
          liClassNames={liClassNames}
          key={key}
          handleDeleteEntry={this.handleDeleteEntry}
          handlePlayNow={this.handlePlayNow}
          switchPlaylistItems={this.switchPlaylistItems} />
      );

    }, this);

    if(dragActive && this.state.draggingPos === this.playlistCount - 1){
      playlistElements.push(<li className="ghost-playlist-item" key={"ghost-"+this.playlistCount}></li>)
    } 

    return (
      <div>
        <ul className="playlist" ref="playlist">
          {playlistElements}
        </ul>
        { playlistElements.length ? 
          <div className="keyboard-shortcuts desktop">
            <h4>
              Tip: Keyboard Shortcuts
              <i className="far fa-keyboard"></i>
            </h4>
            <p>You can use <strong>Space</strong> to toggle play/pause and <strong>/</strong> to focus the search box.</p>
            <p><strong>Up Arrow/k</strong> and <strong>Down Arrow/j</strong> navigates throught the playlist, press <strong>Enter</strong> to play the selected track.</p>
          </div> : '' }
      </div>
    )
  }
});

module.exports = Playlist;
